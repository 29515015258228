import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/app-modules/auth/services/profile.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-brs-header',
  templateUrl: './brs-header.component.html',
  styleUrls: ['./brs-header.component.scss'],
})
export class BrsHeaderComponent implements OnInit {
  @Input() user = {};
  @Input() productTitle = 'Product Name';
  @Input() applicationInfo;
  public APP_CONFIG: any;
  public isSuperAdmin = false;
  public organizationName;
  public userInfo;
  public activeRole = 'USER';
  protected ngUnsubscribe = new Subject();

  companyTitle = 'IHS Markit';

  constructor(protected router: Router, profileService: ProfileService) {
    this.userInfo = profileService.getUser();
    this.APP_CONFIG = {};


  }

  ngOnInit() {

  }

}

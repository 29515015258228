import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import * as contactActions from './contact.actions';
import { Contact } from './models/contact.modul';
import { ContactService } from './services/contact.service';
import { OrganizationStateModel } from '../organizations/organizations.state';
import * as organizationsActions from '../organizations/organizations.actions';

export interface ContactStateModel {
  contacts: Contact[];
  selectedContact: Contact;
}

@State<ContactStateModel>({
  name: 'contacts',
  defaults: {
    contacts: null,
    selectedContact: null
  }
})
@Injectable()
export class ContactState {
  constructor(private contactService: ContactService) {
  }

  @Selector()
  static getContactList(state: ContactStateModel) {
    return state.contacts;
  }

  @Action(contactActions.AddContact)
  addContact({getState, patchState}: StateContext<ContactStateModel>, {payload}: contactActions.AddContact) {
    return this.contactService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          contacts: [...(state.contacts || []), result]
        });
      }));
  }

  @Action(contactActions.GetContacts)
  getContacts({getState, setState}: StateContext<ContactStateModel>, {id}: contactActions.GetContacts) {
    return this.contactService.fetchUsersPerBusinessGroup(id)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          contacts: result,
        });
      }));
  }


  @Action(contactActions.UpdateContact)
  updateContact({getState, setState}: StateContext<ContactStateModel>, {payload}: contactActions.UpdateContact) {
    return this.contactService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const orgList = [...state.contacts];
        const orgIndex = orgList.findIndex(item => item.id === payload.id);
        orgList[orgIndex] = payload;
        setState({
          ...state,
          contacts: orgList,
      });
  }))
}

  @Action(contactActions.DeleteContact)
  deleteContact({getState, setState}: StateContext<ContactStateModel>, {payload}: contactActions.DeleteContact) {
    return this.contactService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.contacts.filter(item => item.id !== payload.id);
      setState({
        ...state,
        contacts: filteredArray,
      });

    }));
  }

  @Action(contactActions.GetContact)
  getContact({ getState, setState }: StateContext<ContactStateModel>,{ id }: contactActions.GetContact) {
    return this.contactService.getRow(id)
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            selectedContact: result,
          });
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { AuthConfig, JwksValidationHandler, OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Subject } from 'rxjs';
import { HttpRequest } from '@angular/common/http';
import { SsoTokens } from '../models/sso-tokens';

const IS_IMPLICIT_FLOW = false;
const IS_CODE_FLOW = true;

const ISSUERS = {
  prod: {
    issuer: 'https://sam.ihsmarkit.com:443/sso/oauth2',
    clientId: 'finance-brs-prod-zPR5MLQE9a'
  },
  uat: {
    issuer: 'https://sam.samexternal.net:443/sso/oauth2',
    clientId: 'finance-brs-1PAnOs8Trl'
  }
};

const TEST_ADDRESSES = new Set([
  'http://localhost:4200',
  'https://dev.adminportal.researchmanager.spglobal.com',
  'https://qa.adminportal.researchmanager.spglobal.com',
  'https://uat.adminportal.researchmanager.spglobal.com'
]);

@Injectable()
export class OpenidSsoService {

  public subject = new Subject<any>();

  constructor (
    private oauthService: OAuthService
  ) {}

  configureSsoLogin(redirectUri: string) {
    const { issuer, clientId } = this.getIssuer();
    let authConfig: AuthConfig = {
      issuer,
      clientId,
      redirectUri,
      scope: 'openid profile email',
      clearHashAfterLogin: false
    };

    if (IS_IMPLICIT_FLOW) {
      authConfig = {
        ...authConfig,
        loginUrl: issuer + '/authorize-page',
        logoutUrl: issuer + '/connect/endSession',
        tokenEndpoint: issuer + '/access_token',
        sessionCheckIFrameUrl: issuer + '/connect/checkSession',
        userinfoEndpoint: issuer + '/userinfo'
      };
    }

    if (IS_CODE_FLOW) {
      authConfig.responseType = 'code';
    }
    this.oauthService.configure(authConfig);

    if (!IS_IMPLICIT_FLOW) {
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    }
    this.oauthService.events.subscribe((event: OAuthEvent) => {
      if (event instanceof OAuthErrorEvent) {

        this.subject.next({type: 'error', event });
      } else {

        this.subject.next({type: 'info', event });
      }
    });
  }

  proceedSsoDiscovery()  {
    if (IS_IMPLICIT_FLOW) {
      this.oauthService.tryLogin().then(res => {
        });
    } else {
      this.oauthService.loadDiscoveryDocument().then(doc => {
        if (IS_CODE_FLOW) {
          this.oauthService.tryLoginCodeFlow().then(res => {
          });
        } else {
          this.oauthService.tryLogin().then(res => {

          });
        }
      });
    }
  }

  proceedSsoLogin() {
    if (IS_IMPLICIT_FLOW) {
      this.oauthService.initImplicitFlowInternal();
    } else {
      this.oauthService.loadDiscoveryDocumentAndLogin().then(res => {
      });
    }
  }

  getTokens(): SsoTokens {
    return {
      idToken: this.oauthService.getIdToken(),
      accessToken: this.oauthService.getAccessToken()
    };
  }

  logOut() {
    this.oauthService.logOut(true);
  }

  private getIssuer() {
    if (TEST_ADDRESSES.has(window.location.origin)) {
      return ISSUERS.uat;
    }

    return ISSUERS.prod;
  }

  isSsoRequest(req: HttpRequest<any>) {
    const { issuer } = this.getIssuer();

    if (req.url.indexOf(issuer.split(':')[0]) >= 0) {
      return true;
    }

    return false;
  }
}

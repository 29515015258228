import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import * as servicesActions from './services.actions';
import { Service } from './models/services.model';
import { ServicesService } from './services/services.service';

export interface ServiceStateModel {
  services: Service[];
  selectedService: Service;
}

@State<ServiceStateModel>({
  name: 'services',
  defaults: {
    services: null,
    selectedService: null
  }
})
@Injectable()
export class ServicesState {
  constructor(private servicesService: ServicesService) {
  }

  @Selector()
  static getServiceList(state: ServiceStateModel) {
    return state.services;
  }

  @Action(servicesActions.AddService)
  addService({ getState, patchState }: StateContext<ServiceStateModel>, { payload }: servicesActions.AddService) {
    return this.servicesService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          services: [...state.services, result]
        });
      }));
  }

  @Action(servicesActions.GetServices)
  getServices({ getState, setState }: StateContext<ServiceStateModel>, { businessGroupId }: servicesActions.GetServices) {
    return this.servicesService.fetchServicesPerBusinessGroup(businessGroupId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          services: result,
        });
      }));
  }


  @Action(servicesActions.UpdateService)
  updateService({ getState, setState }: StateContext<ServiceStateModel>, { payload }: servicesActions.UpdateService) {
    return this.servicesService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const orgList = [...state.services];
        const orgIndex = orgList.findIndex(item => item.id === payload.id);
        orgList[orgIndex] = payload;
        setState({
          ...state,
          services: orgList,
        });
      }));
  }


  @Action(servicesActions.DeleteService)
  deleteService({ getState, setState }: StateContext<ServiceStateModel>, { payload }: servicesActions.DeleteService) {
    return this.servicesService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.services.filter(item => item.id !== payload.id);
      setState({
        ...state,
        services: filteredArray,
      });
    }));
  }

}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AddOrganization } from '../../organizations.actions';
import { ToastComponent } from '../../../../shared/toast/toast.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersListPageComponent } from 'app/feature-modules/users/components/users-list-page/users-list-page.component';
import { OrganizationsService } from '../../services/organizations.service';
import { OrgUploadDlgComponent } from 'app/shared/org-upload-dlg/org-upload-dlg.component';

@Component({
  selector: 'app-organizations-list-page',
  templateUrl: './organizations-list-page.component.html',
  styleUrls: ['./organizations-list-page.component.scss']
})
export class OrganizationsListPageComponent implements OnInit {

  public loadingIndicator = true;
  addOrgForm: FormGroup;
  modalService: typeof MAT_DIALOG_DATA;

  constructor(protected router: Router,
              private formBuilder: FormBuilder,
              private store: Store,
              public toast: ToastComponent,
              public dialog: MatDialog,
              private organizationsService: OrganizationsService) {

  }

  ngOnInit() {
    this.addOrgForm = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required),
      code: new FormControl(''),
      active: new FormControl(true),
      csaBroker: new FormControl(false)
    });
  }

  addOrg(orgForm: NgForm) {
    this.store.dispatch(new AddOrganization(this.addOrgForm.value))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addOrgForm)))
      .subscribe((item) => {
        this.addOrgForm.reset({ active: true });
        orgForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }

  openUploadDialog() {
    const dialogRef = this.dialog.open(OrgUploadDlgComponent, {
      position: {
        top: '15%'
      },
      width: '1000px',
      height: '200px'
    });
    }


  private loadSummary() {
    this.loadingIndicator = true;
  }


  showCsaUsers(){
    this.dialog.open(UsersListPageComponent, {
      width: '1000px',
      height: '550px'
    });
  }

  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthModule } from '../auth/auth.module';
import { HeaderComponent } from './components/header/header.component';
/* import { BrsUiModule } from 'brs-ui'; */
import { BrsHeaderComponent } from './components/brs-header/brs-header.component';
import { BrsPrimaryMenuComponent } from './components/brs-primary-menu/brs-primary-menu.component';



@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    NgbModule,
    RouterModule,
  /*   BrsUiModule */
  ],
  declarations: [
    HeaderComponent,
    BrsHeaderComponent,
    BrsPrimaryMenuComponent
  ],
  providers: [
  ],
  exports: [
    HeaderComponent,
    BrsHeaderComponent,
    BrsPrimaryMenuComponent
  ]
})
export class CoreModule {
}

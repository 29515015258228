import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { BusinessTeam } from '../models/business-team.model';

const BUSINESS_TEAMS_API = '/api/team';

@Injectable()
export class BusinessTeamsService {

  constructor(private http: HttpClient) {
  }

  fetchBusinessTeamsPerBusinessUnit(businessUnitId: string): Observable<BusinessTeam[]> {
    const fetchEnpoint = `${BUSINESS_TEAMS_API}/businessUnit/${businessUnitId}/list`;
    return this.http.get<BusinessTeam[]>(fetchEnpoint);
  }

  fetchBusinessTeamsPerOrganization(orgId: string): Observable<BusinessTeam[]> {
    const fetchEnpoint = `${BUSINESS_TEAMS_API}/organization/${orgId}/list`;
    return this.http.get<BusinessTeam[]>(fetchEnpoint);
  }

  getRow(businessUnitId: string): Observable<BusinessTeam> {
    const fetchEndpoint = `${BUSINESS_TEAMS_API}/${businessUnitId}`;
    return this.http.get<BusinessTeam>(fetchEndpoint);
  }

  addRow(item: BusinessTeam): Observable<BusinessTeam> {
    const endpoint = `${BUSINESS_TEAMS_API}`;
    return this.http.post<BusinessTeam>(endpoint, { ...item });
  }

  updateRow(updateItem: BusinessTeam): Observable<BusinessTeam> {
    const endpoint = `${BUSINESS_TEAMS_API}`;

    return this.http.patch<BusinessTeam>(endpoint, { ...updateItem });
  }

  deleteRow(item: BusinessTeam): Observable<any> {
    const endpoint = `${BUSINESS_TEAMS_API}/${item.id}`;

    return this.http.delete(endpoint);
  }

}

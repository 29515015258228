import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxsModule } from '@ngxs/store';

/* Modules */
/* import { BrsUiModule } from 'brs-ui'; */
import { MaterialModule } from '../../material.module';
import { OrganizationsRoutingModule } from './organizations-routing.module';
import { SharedModule } from '../../shared/shared.module';

/* Components */
import { OrganizationsPageComponent } from './components/organizations-page/organizations-page.component';
import { OrganizationsListPageComponent } from './components/organizations-list-page/organizations-list-page.component';
import { OrganizationsListComponent } from './components/organizations-list/organizations-list.component';
import { OrgEditDialogComponent } from './components/org-edit-dialog/org-edit-dialog.component';

/* Services */
import { OrganizationsService } from './services/organizations.service';

/* States */
import { OrganizationState } from './organizations.state';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    OrganizationsRoutingModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    /* BrsUiModule, */
    SharedModule,
    NgxsModule.forFeature([
      OrganizationState
    ])
  ],
  declarations: [
    OrganizationsPageComponent,
    OrganizationsListPageComponent,
    OrganizationsListComponent,
    OrgEditDialogComponent
  ],
  providers: [OrganizationsService],
  entryComponents: [OrgEditDialogComponent],
  exports: []
})
export class OrganizationsModule {
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxsModule } from '@ngxs/store';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';

/* Services */
import { UsersService } from './services/users.service';

/* States */
import { UsersState } from './users.state';

/* Components */
import { UsersListPageComponent } from './components/users-list-page/users-list-page.component';
import { UserEditDialogComponent } from './components/user-edit-dialog/user-edit-dialog.component';
import { CreateCsaUserComponent } from './components/create-csa-user/create-csa-user.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    UsersRoutingModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      UsersState
    ]),
    SharedModule
  ],
  declarations: [
    UsersListPageComponent,
    UserEditDialogComponent,
    CreateCsaUserComponent,
    DeleteConfirmationDialogComponent
  ],
  providers: [UsersService],
  entryComponents: [UserEditDialogComponent, DeleteConfirmationDialogComponent],
  exports: []
})
export class UsersModule {
}

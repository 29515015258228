import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService) {

  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    const authUserToken = this.storage.getToken();

    if (authUserToken) {
      const cloned = req.clone({
        headers: req.headers.set('brs_token', authUserToken)
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }

}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxsModule } from '@ngxs/store';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactRoutingModule } from './contact-routing.module';

/* Services */
import { ContactService } from './services/contact.service';

/* States */
import { ContactState } from './contact.state';

/* Components */
import { ContactListPageComponent } from './components/contact-list-page/contact-list-page.component';
import { ContactEditDialogComponent } from './components/contact-edit-dialog/contact-edit-dialog.component';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    ContactRoutingModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      ContactState
    ]),
    SharedModule
  ],
  declarations: [
    ContactListPageComponent,
    ContactEditDialogComponent
  ],
  providers: [ContactService],
  entryComponents: [ContactEditDialogComponent],
  exports: []
})
export class ContactModule {
}

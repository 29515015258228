import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';

import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';

/* Modules */

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
import { UsersModule } from './feature-modules/users/users.module';
import { ContactModule } from './feature-modules/contacts/contact.module';
import { AppCoreModules } from './app-modules/app-core.modules';
import { OrganizationsModule } from './feature-modules/organizations/organizations.module';

/* import { BrsUiModule } from 'brs-ui'; */

/* Components*/

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HierarchyModule } from './feature-modules/hierarchy/hierarchy.module';
import { ServiceUnavailableInterceptor } from './interceptors/service-unavailable.interceptor';
import { PageUnavailableComponent } from './components/page-unavailable/page-unavailable.component';
import { AppConfigService } from './app-modules/auth/services/app-config.service';

export function appConfig(config: AppConfigService) {
  return () => config.load();
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    PageUnavailableComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppCoreModules,
    OrganizationsModule,
    UsersModule,
    ContactModule,
    HierarchyModule,
    NgbModule,
    MaterialModule,
    NgxsModule.forRoot(),
    SharedModule,
   /*  BrsUiModule, */
    NgxBreadcrumbsModule
  ],
  providers: [
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServiceUnavailableInterceptor,
      multi: true
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig,
      multi: true,
      deps: [AppConfigService]

    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as teamsActions from '../../business-teams.actions';
import { BusinessTeam } from '../../models/business-team.model';
import { BusinessUnitsState } from '../../../business-units/business-units.state';
import { BusinessUnit } from '../../../business-units/models/business-unit.model';


@Component({
  selector: 'app-business-team-edit-dialog',
  templateUrl: './business-team-edit-dialog.component.html',
  styleUrls: ['./business-team-edit-dialog.component.scss']
})
export class BusinessTeamEditDialogComponent implements OnInit {
  @Input() isParentHierarchyEditable = false;

  editTeamForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  active = new FormControl('', Validators.required);
  businessUnitId = new FormControl('', Validators.required);

  unitsList: BusinessUnit[] = <BusinessUnit[]>[];

  @Select(BusinessUnitsState.getBusinessUnitList) unitList$: Observable<BusinessUnit[]>;
  public searchUnitSubject: BehaviorSubject<string> = new BehaviorSubject('');
  filteredUnits$: Observable<BusinessUnit[]>;

  public ngUnsubscribe = new Subject();

  constructor(public dialogRef: MatDialogRef<BusinessTeamEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: BusinessTeam) {
    this.data.businessUnit = null;
  }

  ngOnInit() {
    this.editTeamForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      active: this.active,
      businessUnitId: this.businessUnitId
    });

    if (this.isParentHierarchyEditable) {
      this.unitList$
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((items: BusinessUnit[]) => {
          this.unitsList = items || [];
          this.data.businessUnit = this.unitsList.find(item => item.id === this.data.businessUnitId)
        });

      this.filteredUnits$ =
        combineLatest([this.unitList$, this.searchUnitSubject])
          .pipe(
            map(([unitList, searchValue = '']) => this._filter(searchValue, unitList))
          );
    }
  }

  private _filter(searchValue: string, unitList): any {
    const search = searchValue.trim().toLowerCase();
    if (!search) {
      return unitList;
    }
    return unitList.filter(option => option.name.toLowerCase().indexOf(search) === 0);
  }

  getOptionText(option) {
    return option && option.name;
  }

  onSearchChange($event) {
    if (typeof $event === 'string') {
      this.searchUnitSubject.next($event);
    }
  }

  submit() {
    if (this.isParentHierarchyEditable) {
      this.data.businessUnitId = this.data.businessUnit.id;
    }
    this.store.dispatch(new teamsActions.UpdateBusinessTeam(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editTeamForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        if( form.controls[beError.field]) {
          form.controls[beError.field].setErrors({backend: beError.defaultMessage});
        } else {
          form.setErrors({backend: beError.defaultMessage});
        }

      });
    }

    return NEVER;
  }
}

import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
  imports: [
    CoreModule,
    AuthModule

  ],
  declarations: [],

  exports: [
    CoreModule,
    AuthModule,

  ]
})
export class AppCoreModules {
}

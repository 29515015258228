import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import * as businessUnitActions from '../../business-units.actions';
import { BusinessUnit } from '../../models/business-unit.model';
import { Group } from '../../../business-groups/models/group.model';
import { GroupsState } from '../../../business-groups/groups.state';

@Component({
  selector: 'app-business-unit-edit-dialog',
  templateUrl: './business-unit-edit-dialog.component.html',
  styleUrls: ['./business-unit-edit-dialog.component.scss']
})
export class BusinessUnitEditDialogComponent implements OnInit {
  @Input() isParentHierarchyEditable = false;

  editUnitForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  active = new FormControl('', Validators.required);
  businessGroupId = new FormControl('', Validators.required);
  groupsList: Group[] = <Group[]>[];

  @Select(GroupsState.getGroupList) groupList$: Observable<Group[]>;
  public searchGroupSubject: BehaviorSubject<string> = new BehaviorSubject('');
  filteredGroups$: Observable<Group[]>;

  public ngUnsubscribe = new Subject();


  constructor(public dialogRef: MatDialogRef<BusinessUnitEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: BusinessUnit) {
    this.data.businessGroup = null;
  }

  ngOnInit() {
    this.editUnitForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      businessGroupId: this.businessGroupId,
      active: this.active
    });

    if (this.isParentHierarchyEditable) {
      this.groupList$
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe((items: Group[]) => {
          this.groupsList = items || [];
          this.data.businessGroup = this.groupsList.find(item => item.id === this.data.businessGroupId)
        });

      this.filteredGroups$ =
        combineLatest([this.groupList$, this.searchGroupSubject])
          .pipe(
            map(([groupList, searchValue = '']) => this._filter(searchValue, groupList))
          );
    }
  }

  private _filter(searchValue: string, groupList): any {
    const search = searchValue.trim().toLowerCase();
    if (!search) {
      return groupList;
    }
    return groupList.filter(option => option.name.toLowerCase().indexOf(search) === 0);
  }

  getOptionText(option) {
    return option && option.name;
  }

  onSearchChange($event) {
    if (typeof $event === 'string') {
      this.searchGroupSubject.next($event);
    }
  }

  submit() {
    if (this.isParentHierarchyEditable) {
      this.data.businessGroupId = this.data.businessGroup.id;
    }
    this.store.dispatch(new businessUnitActions.UpdateBusinessUnit(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editUnitForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        if (form.controls[beError.field]) {
          form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
        } else {
          form.setErrors({ backend: beError.defaultMessage });
        }

      });
    }

    return NEVER;
  }
}

import { State, Selector, Action, StateContext } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import * as organizationsActions from './organizations.actions';
import { Organization } from './models/organization.model';
import { OrganizationsService } from './services/organizations.service';
import { ToastComponent } from '../../shared/toast/toast.component';

export interface OrganizationStateModel {
  organizations: Organization[];
  selectedOrganization: Organization;
}

@State<OrganizationStateModel>({
  name: 'organizations',
  defaults: {
    organizations: null,
    selectedOrganization: null
  }
})
@Injectable()
export class OrganizationState {
  constructor(public organizationsService: OrganizationsService,
              public toast: ToastComponent) {
  }

  @Selector()
  static getOrganizationsList(state: OrganizationStateModel) {
    return state.organizations;
  }

  @Selector()
  static getSelectedOrganization(state: OrganizationStateModel) {
    return state.selectedOrganization;
  }

  @Action(organizationsActions.AddOrganization)
  addOrganization({ getState, patchState }: StateContext<OrganizationStateModel>, { payload }: organizationsActions.AddOrganization) {
    return this.organizationsService.addOrganization(payload)
      .pipe(
        tap((result) => {
          const state = getState();
          patchState({
            organizations: [...(state.organizations || []), result]
          });
        }),
        catchError(error => {
          return throwError(error);
        }));
  }

  @Action(organizationsActions.GetOrganization)
  getOrganization({ getState, setState }: StateContext<OrganizationStateModel>,{ orgId }: organizationsActions.GetOrganization) {
    return this.organizationsService.getOrganization(orgId)
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            selectedOrganization: result,
          });
        })
      );
  }

  @Action(organizationsActions.GetOrganizations)
  getOrganizations({ getState, setState }: StateContext<OrganizationStateModel>) {
    return this.organizationsService.getOrganizations()
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            organizations: result,
          });
        })
      );
  }


  @Action(organizationsActions.UpdateOrganization)
  updateOrganization({ getState, setState }: StateContext<OrganizationStateModel>, { payload }: organizationsActions.UpdateOrganization) {
    return this.organizationsService.editOrganization(payload)
      .pipe(tap((result) => {
        const state = getState();
        const orgList = [...state.organizations];
        const orgIndex = orgList.findIndex(item => item.id === payload.id);
        orgList[orgIndex] = payload;
        setState({
          ...state,
          organizations: orgList,
        });
      }));
  }


  @Action(organizationsActions.DeleteOrganization)
  deleteOrganization({ getState, setState }: StateContext<OrganizationStateModel>, { payload }: organizationsActions.DeleteOrganization) {
    return this.organizationsService.deleteOrganization(payload)
      .pipe(tap(() => {
        const state = getState();
        const filteredArray = state.organizations.filter(item => item.id !== payload.id);
        setState({
          ...state,
          organizations: filteredArray,
        });
      }));
  }

  @Action(organizationsActions.SetSelectedOrganization)
  setSelectedOrganizations({ getState, setState }: StateContext<OrganizationStateModel>,
                           { payload }: organizationsActions.SetSelectedOrganization) {
    const state = getState();
    setState({
      ...state,
      selectedOrganization: payload,
    });
  }

}

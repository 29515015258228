import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';
import { NgxsModule } from '@ngxs/store';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { BusinessTeamsRoutingModule } from './business-teams-routing.module';

/* Services */
import { BusinessTeamsService } from './services/business-teams.service';

/* States */

import { BusinessTeamsState } from './business-teams.state';

/* Components */
import { BusinessTeamsPageComponent } from './components/business-teams-page/business-teams-page.component';
import {
  BusinessTeamEditDialogComponent,
} from './components/business-team-edit-dialog/business-team-edit-dialog.component';
import { BusinessTeamListViewComponent } from './components/business-team-list-view/business-team-list-view.component';

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      BusinessTeamsState
    ]),
    SharedModule,
    BusinessTeamsRoutingModule,
  ],
  declarations: [
    BusinessTeamsPageComponent,
    BusinessTeamListViewComponent,
    BusinessTeamEditDialogComponent,
  ],
  providers: [BusinessTeamsService],
  exports: [BusinessTeamListViewComponent]
})
export class BusinessTeamsModule {
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { MatDialog } from '@angular/material/dialog';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import * as groupsActions from '../../groups.actions';
import * as orgsActions from '../../../organizations/organizations.actions';

import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';
import { GroupsState } from '../../groups.state';
import { Group } from '../../models/group.model';
import { BusinessGroupsEditDialogComponent } from '../business-group-edit-dialog/business-groups-edit-dialog.component';
import { GROUP_CURRENCY } from '../../constants/currency.constant';

@Component({
  selector: 'app-business-groups-list-view',
  templateUrl: './business-groups-list-view.component.html',
  styleUrls: ['./business-groups-list-view.component.scss']
})
export class BusinessGroupsListViewComponent implements OnInit {
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  addGroupForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('');
  currency = new FormControl('');
  vendorId = new FormControl('');
  questionnaireId = new FormControl('');
  type = new FormControl('', Validators.required);
  editing = {};
  rows = [];
  datasource = [];
  isLoading = true;
  organizationId: string;
  currentOrganization: Organization;
  currencyList = Object.values(GROUP_CURRENCY);
  filteredCurrency: string[] = [...this.currencyList];
  typeOptions: any[] = [
    {value: 'CSA_BROKER', label: 'CSA Broker'},
    {value: 'SERVICE_PROVIDER', label: 'Service Provider'}
  ];
  action = 'ADD';

  @Select(GroupsState.getGroupList) groupsList$: Observable<Group[]>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;

  public ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private activeRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {
    this.addGroupForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      active: new FormControl(true),
      currency: this.currency,
      vendorId: this.vendorId,
      questionnaireId: this.questionnaireId,
      type: this.type,
      cmEnabled: new FormControl(true),
      action: this.action
    });

  }

  ngOnInit() {
    this.isLoading = true;
    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.store.dispatch(new groupsActions.GetGroups(this.organizationId));
      });

    this.groupsList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });

    this.org$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((org: Organization) => {
        if (org) {
          this.currentOrganization = org;
          this.setBreadcrumbs(this.currentOrganization);
          if (this.currentOrganization?.type === 'BUY_SIDE') {
            this.typeOptions.push({value: 'ASSET_MANAGER', label: 'Asset Manager'});
            this.type.setValue('ASSET_MANAGER');
            this.type.disable();
          }
        } else if (this.organizationId) {
          this.store.dispatch(new orgsActions.GetOrganization(this.organizationId));
        }
      });

  }

  onSearchCurrency(event: string): string[] {
    const search = event && event.trim().toLowerCase();
    if (!search) {
      return this.filteredCurrency = [...this.currencyList];
    }
    this.filteredCurrency = this.currencyList.filter(option => option.toLowerCase().indexOf(search) === 0);
    return this.filteredCurrency;
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  addGroup(): void {
    this.addGroupForm.value.organizationId = this.organizationId;
    this.addGroupForm.value.type = this.addGroupForm.controls['type'].value;
    this.store.dispatch(new groupsActions.AddGroup(this.addGroupForm.value))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addGroupForm)))
      .subscribe((item) => {
        this.addGroupForm.reset({
          active: true,
          type: this.currentOrganization?.type === 'BUY_SIDE' ? 'ASSET_MANAGER' : '',
          cmEnabled: true
        });
        this.toast.setMessage('item added successfully.', 'success');
      });

  }

  startEdit(row: Group) {
    const dialogRef = this.dialog.open(BusinessGroupsEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }

  deleteGroup(group: Group) {
    this.store.dispatch(new groupsActions.DeleteGroup(group))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addGroupForm)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }

  navigateToServices(row: Group) {
    this.store.dispatch(new groupsActions.SetSelectedGroup(row));
    this.router.navigate(['./', `${row.id}`, 'services'], { relativeTo: this.activeRoute });
  }

  navigateToBusinessUnit(row: Group) {
    this.store.dispatch(new groupsActions.SetSelectedGroup(row));
    this.router.navigate(['./', `${row.id}`, 'businessUnit'], { relativeTo: this.activeRoute });
  }

  isServicesDisabled() {
    return this.currentOrganization && this.currentOrganization.type !== 'SP';
  }

  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: 'Organizations', url: `../../list`, params: [] },
        {
          label: `${ currentOrganization && currentOrganization.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }

  navigateToContact(row: Group) {
    this.store.dispatch(new groupsActions.SetSelectedGroup(row));
    this.router.navigate(['./', `${row.id}`, 'contact'], {relativeTo: this.activeRoute});
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

import { ToastComponent } from '../../../../shared/toast/toast.component';

import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';
import { BusinessUnitsState } from '../../../business-units/business-units.state';
import { BusinessUnit } from '../../../business-units/models/business-unit.model';
import { BusinessTeamEditDialogComponent } from '../business-team-edit-dialog/business-team-edit-dialog.component';
import { BusinessTeam } from '../../models/business-team.model';
import * as teamsActions from '../../business-teams.actions';
import { BusinessTeamsState } from '../../business-teams.state';
import * as orgsActions from '../../../organizations/organizations.actions';

@Component({
  selector: 'app-business-team-list-view',
  templateUrl: './business-team-list-view.component.html',
  styleUrls: ['./business-team-list-view.component.scss']
})
export class BusinessTeamListViewComponent implements OnInit {
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  addTeamForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  businessUnitId = new FormControl('', Validators.required);
  editing = {};
  rows = [];
  datasource = [];
  isLoading = true;
  organizationId: string;
  unitId?: string;
  unitsList: BusinessUnit[] = <BusinessUnit[]>[];

  @Select(BusinessUnitsState.getBusinessUnitList) businessUnitList$: Observable<BusinessUnit[]>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;
  @Select(BusinessTeamsState.getBusinessTeamList) businessTeamList$: Observable<BusinessTeam[]>;

  public searchGroupSubject: BehaviorSubject<string> = new BehaviorSubject('');
  filteredUnits$: Observable<BusinessUnit[]>;

  public ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private activeRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {
    this.addTeamForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      businessUnitId: this.businessUnitId,
      active: new FormControl(true)
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.unitId = params.get('businessUnitId');
        this.store.dispatch(new orgsActions.GetOrganization(this.organizationId));
        this.store.dispatch(new teamsActions.GetOrganizationBusinessTeamList(this.organizationId));
      });

    this.businessTeamList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items: BusinessTeam[]) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });


    this.org$.pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((org) => {
        this.setBreadcrumbs(org);
      });


    this.businessUnitList$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((items: BusinessUnit[]) => {
        this.unitsList = items || [];

      });

    this.filteredUnits$ =
      combineLatest([this.businessUnitList$, this.searchGroupSubject])
        .pipe(
          map(([unitList, searchValue = '']: [BusinessUnit[], string]) => this._filter(searchValue, unitList))
        );

  }

  private _filter(searchValue: string, unitList: BusinessUnit[]): any {
    const search = searchValue.trim().toLowerCase();
    if (!search) {
      return unitList;
    }
    return unitList.filter(option => option.name.toLowerCase().indexOf(search) === 0);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  onSearchChange($event) {
    if (typeof $event === 'string') {
      this.searchGroupSubject.next($event);
    }
  }

  addTeam(teamFrom: NgForm): void {

    let newTeam = <BusinessTeam> {
      ...this.addTeamForm.value,
      businessUnitId: this.addTeamForm.value.businessUnitId.id
    };

    this.store.dispatch(new teamsActions.AddBusinessTeam(newTeam))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addTeamForm)))
      .subscribe((item) => {
        this.addTeamForm.reset({ active: true });
        teamFrom.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }


  startEdit(row: BusinessUnit) {
    const dialogRef = this.dialog.open(BusinessTeamEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.componentInstance.isParentHierarchyEditable = true;

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }


  deleteTeam(row: BusinessTeam) {
    this.store.dispatch(new teamsActions.DeleteBusinessTeam(row))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addTeamForm)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }

  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: 'Organizations', url: `../../list`, params: [] },
        {
          label: `${ currentOrganization && currentOrganization.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }

  getOptionText(option) {
    return option && option.name;
  }


  getUnitNameById(id: string) {
    const unit = this.unitsList.find((item: BusinessUnit) => item.id === id);
    return unit ? unit.name : 'No unit';
  }

}



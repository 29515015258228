import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';

import { Group } from '../models/group.model';

const GROUP_API = '/api/businessGroup';

@Injectable()
export class GroupsService {


  constructor(private http: HttpClient) {
  }


  fetchGroupsPerOrganization(orgId: string): Observable<Group[]> {
    const fetchEnpoint = `${GROUP_API}/organization/${orgId}/list`;
    return this.http.get<Group[]>(fetchEnpoint);
  }

  getRow(groupId: string): Observable<Group> {
    const fetchEndpoint = `${GROUP_API}/${groupId}`;
    return this.http.get<Group>(fetchEndpoint);
  }

  addRow(item: Group): Observable<Group> {
    const endpoint = `${GROUP_API}`;
    return this.http.post<Group>(endpoint, { ...item });
  }

  updateRow(updateItem: Group): Observable<Group> {
    const endpoint = `${GROUP_API}`;

    return this.http.patch<Group>(endpoint, { ...updateItem });
  }

  deleteRow(item: Group): Observable<any> {
    const endpoint = `${GROUP_API}/${item.id}`;

    return this.http.delete(endpoint);
  }


}

import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from './menu-item.model';

@Component({
    selector: 'app-brs-primary-menu',
    templateUrl: 'brs-primary-menu.component.html',
    styleUrls: ['brs-primary-menu.component.scss']
})

export class BrsPrimaryMenuComponent implements OnInit {

    @Input('menuItems') menuItems: MenuItem[];

    constructor() { }

    ngOnInit() { }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import * as orgsActions from '../../../organizations/organizations.actions';
import { GroupsState } from '../../../business-groups/groups.state';
import { Group } from '../../../business-groups/models/group.model';
import { BusinessUnitEditDialogComponent } from '../business-unit-edit-dialog/business-unit-edit-dialog.component';
import { BusinessUnit } from '../../models/business-unit.model';
import * as businessUnitActions from '../../business-units.actions';
import { BusinessUnitsState } from '../../business-units.state';
import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';

@Component({
  selector: 'app-business-unit-list-view',
  templateUrl: './business-unit-list-view.component.html',
  styleUrls: ['./business-unit-list-view.component.scss']
})
export class BusinessUnitListViewComponent implements OnInit {
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  addUnitForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  businessGroupId = new FormControl('', Validators.required);
  editing = {};
  rows = [];
  datasource = [];
  isLoading = true;
  organizationId: string;
  groupId?: string;
  group: Group;
  groupsList: Group[] = <Group[]>[];
  searchField = '';

  @Select(BusinessUnitsState.getBusinessUnitList) businessUnitList$: Observable<BusinessUnit[]>;
  @Select(GroupsState.getGroupList) groupList$: Observable<Group[]>;
  @Select(GroupsState.getSelectedGroup) group$: Observable<Group>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;

  public searchGroupSubject: BehaviorSubject<string> = new BehaviorSubject('');
  filteredGroups$: Observable<Group[]>;

  public ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private activeRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {
    this.addUnitForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      businessGroupId: this.businessGroupId,
      active: new FormControl(true),

    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.store.dispatch(new orgsActions.GetOrganization(this.organizationId));
        this.store.dispatch(new businessUnitActions.GetOrganizationBusinessUnitList(this.organizationId));
      });


    this.businessUnitList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });

    this.org$.pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe((org) => {
        this.setBreadcrumbs(org);
      });


    this.groupList$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((items: Group[]) => {
        this.groupsList = items || [];
      });

    this.filteredGroups$ =
      combineLatest([this.groupList$, this.searchGroupSubject])
        .pipe(
          map(([groupList, searchValue = '']) => this._filter(searchValue, groupList))
        );

  }

  private _filter(searchValue: string, groupList): any {
    const search = searchValue.trim().toLowerCase();
    if (!search) {
      return groupList;
    }
    return groupList.filter(option => option.name.toLowerCase().indexOf(search) === 0);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  onSearchChange($event) {

    if (typeof $event === 'string') {
      this.searchGroupSubject.next($event);
    }

  }

  addBusinessUnit(unitForm: NgForm): void {
    this.addUnitForm.value.businessGroupId = this.addUnitForm.value.businessGroupId.id;

    this.store.dispatch(new businessUnitActions.AddBusinessUnit(this.addUnitForm.value))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addUnitForm)))
      .subscribe((item) => {
        this.addUnitForm.reset({ active: true });
        unitForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }


  startEdit(row: BusinessUnit) {
    const dialogRef = this.dialog.open(BusinessUnitEditDialogComponent, {
      data: { ...row },
    });

    dialogRef.componentInstance.isParentHierarchyEditable = true;

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }


  deleteUnit(row: BusinessUnit) {
    this.store.dispatch(new businessUnitActions.DeleteBusinessUnit(row))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addUnitForm)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }

  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: 'Organizations', url: `../../list`, params: [] },
        {
          label: `${ currentOrganization && currentOrganization.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }

  getOptionText(option) {
    return option && option.name;
  }


  getGroupNameById(id: string) {
    const group = this.groupsList.find((item: Group) => item.id === id);
    return group ? group.name : 'No group';
  }

}



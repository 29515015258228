import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsersService } from 'app/feature-modules/users/services/users.service';
import { ToastComponent } from '../toast/toast.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-org-upload-dlg',
  templateUrl: './org-upload-dlg.component.html',
  styleUrls: ['./org-upload-dlg.component.scss']
})
export class OrgUploadDlgComponent {

  selectedFile: File = null;
  constructor(private http: HttpClient, private userSvc: UsersService, public toast: ToastComponent,public dialog: MatDialog) { }

  onCancel() {
    this.dialog.closeAll();
  }

  onProcessFile() {
     if (this.selectedFile != null ) {
      const loadingDialogRef = this.dialog.open(
        LoadingComponent, {
          disableClose: true,
          data: { condition: true }
        }

      );
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);
      return this.userSvc.uploadServiceProvider(formData)
        .subscribe((response) => {
          loadingDialogRef.close();
          console.log('response', response.response);
          const blob = new Blob([response.response], { type: 'text/csv' });
          saveAs(blob, 'service-provider-import-results.csv');
          this.dialog.closeAll();
          this.toast.setMessage(`Uploaded successfully : ${this.selectedFile.name} `, 'success');
        },
        (error) => {  // Error callback
          loadingDialogRef.close();
          console.error('Error occurred:', error);
          this.toast.setMessage(`An error occurred while uploading the file : ${this.selectedFile.name}`, 'danger');
        }
      );
    }
    }

  onFileSelected($event: any) {
    console.log('onFileSelected', $event);
    this.selectedFile = $event.target.files[0];
  }
}
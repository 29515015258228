import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ProfileService } from './profile.service';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private profileService: ProfileService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.profileService.isLoggedIn() && this.profileService.isCsaAdmin()) {
      return true;
    } else {
      this.router.navigate(['login']);
    }
  }
}

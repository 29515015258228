import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { MatDialog } from '@angular/material/dialog';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import * as teamsActions from '../../business-teams.actions';
import * as businessUnitActions from '../../../business-units/business-units.actions';
import * as businessGroupActions from '../../../business-groups/groups.actions';
import * as organizationsActions from '../../../organizations/organizations.actions';
import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';
import { BusinessTeam } from '../../models/business-team.model';
import { BusinessTeamsState } from '../../business-teams.state';
import {
  BusinessTeamEditDialogComponent,
} from '../business-team-edit-dialog/business-team-edit-dialog.component';
import { BusinessUnitsState } from '../../../business-units/business-units.state';
import { BusinessUnit } from '../../../business-units/models/business-unit.model';
import { GroupsState } from '../../../business-groups/groups.state';
import { Group } from '../../../business-groups/models/group.model';

@Component({
  selector: 'app-business-teams-page',
  templateUrl: './business-teams-page.component.html',
  styleUrls: ['./business-teams-page.component.scss']
})
export class BusinessTeamsPageComponent implements OnInit {
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  addTeamForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  editing = {};
  rows = [];
  datasource = [];
  isLoading = true;
  organizationId: string;
  businessGroupId: string;
  businessUnitId: string;
  group: Group;
  unit: BusinessUnit;
  currentOrganization: Organization;

  @Select(BusinessTeamsState.getBusinessTeamList) businessTeamList$: Observable<BusinessTeam[]>;
  @Select(GroupsState.getSelectedGroup) group$: Observable<Group>;
  @Select(BusinessUnitsState.getSelectedUnit) unit$: Observable<BusinessUnit>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;

  public ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private activeRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {

    this.addTeamForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      active: new FormControl(true)
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.businessGroupId = params.get('businessGroupId');
        this.businessUnitId = params.get('businessUnitId');

        this.store.dispatch(new businessUnitActions.GetBusinessUnit(this.businessUnitId));

      });

    this.businessTeamList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items: BusinessTeam[]) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });

    combineLatest([this.unit$, this.group$, this.org$]).pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe(([unit, group, org]) => {
        if(!unit && this.businessUnitId) {
          this.store.dispatch(new businessUnitActions.GetBusinessUnitList(this.businessUnitId))
        }
        if(!group && this.businessGroupId) {
          this.store.dispatch(new businessGroupActions.GetGroup(this.businessGroupId))
        }
        if(!org && this.organizationId) {
          this.store.dispatch(new organizationsActions.GetOrganization(this.organizationId))
        }
        if (unit) {
          this.setBreadcrumbs(unit, group, org);
        }
      });

    this.unit$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((item: BusinessUnit) => {
        this.unit = item;
        if (this.unit) {
          this.store.dispatch(new teamsActions.GetBusinessTeamList(this.unit.id));
        }
      });

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  addBusinessTeam(teamForm: NgForm): void {
    this.addTeamForm.value.businessUnitId = this.businessUnitId;

    this.store.dispatch(new teamsActions.AddBusinessTeam(this.addTeamForm.value))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addTeamForm)))
      .subscribe((item) => {
        this.addTeamForm.reset({ active: true });
        teamForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }


  startEdit(row: BusinessTeam) {
    const dialogRef = this.dialog.open(BusinessTeamEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }


  delete(team: BusinessTeam) {
    this.store.dispatch(new teamsActions.DeleteBusinessTeam(team))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addTeamForm)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }


  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentUnit: BusinessUnit, currentGroup: Group, currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: `Organizations - ${currentOrganization && currentOrganization.name}`, url: `../../../../list`, params: [] },
        {
          label: `Business Groups - ${currentGroup && currentGroup.name}`,
          url: `../../../../`,
          params: {}
        },
        {
          label: `Business Units`,
          url: `../../`,
          params: {}
        },
        {
          label: `${ currentUnit && currentUnit.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }
}

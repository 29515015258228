import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactListPageComponent } from './components/contact-list-page/contact-list-page.component';


@NgModule({
  imports: [RouterModule.forChild([
    {
      path: ':businessGroupId/contact',
      component: ContactListPageComponent,
    },
  ])],
  exports: [RouterModule],
  providers: []
})
export class ContactRoutingModule {
}

import { Contact } from './models/contact.modul';

export class AddContact {
  static readonly type = '[Contact] Add';

  constructor(public payload: Contact) {
  }
}

export class GetContacts {
  static readonly type = '[Contact] Get List';
  constructor(public id: string) {
  }
}

export class GetContact {
  static readonly type = '[Contact] Get';
  constructor(public id: string) {
  }
}

export class UpdateContact {
  static readonly type = '[Contact] Update';

  constructor(public payload: Contact) {
  }
}

export class DeleteContact {
  static readonly type = '[Contact] Delete';

  constructor(public payload: Contact) {
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OpenidSsoService } from '../../services/openid-sso.service';
import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';


const samResponseUrlFragmentUAT = 'sam.samexternal.net';
const samResponseUrlFragmentPROD = 'sam.ihsmarkit.com';

@Component({
  selector: 'app-authorize-page',
  templateUrl: './authorize-page.component.html',
  styleUrls: ['./authorize-page.component.scss']
})

export class AuthorizePageComponent implements OnInit, OnDestroy {

  private notification = 'Authorizing User...';

  private subscription = new Subscription();

  constructor(
    @Inject('Window') private window: Window,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private openIdSsoService: OpenidSsoService,
    private profileService: ProfileService) {
  }

  ngOnInit() {
    if (!(this.window.location.href.includes(samResponseUrlFragmentUAT)
         || this.window.location.href.includes(samResponseUrlFragmentPROD))) {
          this.router.navigate(['sso-login']);
    }
    const redirectUri = this.route.snapshot.queryParams.returnUrl || this.window.location.origin + '/authorize';
    this.openIdSsoService.configureSsoLogin(redirectUri);
    this.subscription.add(
      this.openIdSsoService.subject
      .subscribe(({type, event}) => {
        if (type === 'error') {
          this.notification = `SSO error: ${event.type}`;
        } else {
          this.ssoSuccessHandler(event);
        }
      }));
    this.openIdSsoService.proceedSsoDiscovery();
  }

  private ssoSuccessHandler(event) {
    if (event.type === 'token_received') {
      const {idToken, accessToken} = this.openIdSsoService.getTokens();
      this.subscription.add(
        this.authService.loginSsoUser(idToken, accessToken)
        .subscribe(_ => {
            const user = this.profileService.getUser();
            const userHomePage = this.profileService.getDefaultHomePage();
            this.router.navigate([userHomePage]);
          },
          (error) => {
            this.notification = error.error.message;
          }));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

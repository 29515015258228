import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import * as usersActions from './users.actions';
import { User } from './models/users.model';
import { UsersService } from './services/users.service';
import { OrganizationStateModel } from '../organizations/organizations.state';
import * as organizationsActions from '../organizations/organizations.actions';

export interface UserStateModel {
  users: User[];
  selectedUser: User;
}

@State<UserStateModel>({
  name: 'users',
  defaults: {
    users: null,
    selectedUser: null
  }
})
@Injectable()
export class UsersState {
  constructor(private usersService: UsersService) {
  }

  @Selector()
  static getUserList(state: UserStateModel) {
    return state.users;
  }

  @Action(usersActions.AddUser)
  addUser({getState, patchState}: StateContext<UserStateModel>, {payload}: usersActions.AddUser) {
    return this.usersService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          users: [...(state.users || []), result]
        });
      }));
  }

  @Action(usersActions.AddCsaUser)
  addCsaUser({getState, patchState}: StateContext<UserStateModel>, {payload}: usersActions.AddUser) {
    return this.usersService.addCsaRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          users: [...(state.users || []), result]
        });
      }));
  }

  @Action(usersActions.GetCsaUsers)
  getCsaUsers({getState, setState}: StateContext<UserStateModel>) {
    return this.usersService.getCsaUsers()
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          users: result,
        });
      }));
  }

  @Action(usersActions.GetUsers)
  getUsers({getState, setState}: StateContext<UserStateModel>, {organizationId}: usersActions.GetUsers) {
    return this.usersService.fetchUsersPerOrganization(organizationId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          users: result,
        });
      }));
  }


  @Action(usersActions.UpdateUser)
  updateUser({getState, setState}: StateContext<UserStateModel>, {payload}: usersActions.UpdateUser) {
    return this.usersService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const orgList = [...state.users];
        const orgIndex = orgList.findIndex(item => item.id === payload.id);
        orgList[orgIndex] = payload;
        setState({
          ...state,
          users: orgList,
        });
      }));
  }


  @Action(usersActions.DeleteUser)
  deleteUser({getState, setState}: StateContext<UserStateModel>, {payload}: usersActions.DeleteUser) {
    return this.usersService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.users.filter(item => item.id !== payload.id);
      setState({
        ...state,
        users: filteredArray,
      });
    }));
  }

  @Action(usersActions.GetUser)
  getOrganization({ getState, setState }: StateContext<UserStateModel>,{ id }: usersActions.GetUser) {
    return this.usersService.getRow(id)
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            selectedUser: result,
          });
        })
      );
  }
}

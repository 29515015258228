import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import * as businessTeamsActions from './business-teams.actions';
import { BusinessTeamsService } from './services/business-teams.service';
import { BusinessTeam } from './models/business-team.model';

export interface BusinessTeamsStateModel {
  businessTeams: BusinessTeam[];
  selectedBusinessTeams: BusinessTeam;
}

@State<BusinessTeamsStateModel>({
  name: 'businessTeams',
  defaults: {
    businessTeams: null,
    selectedBusinessTeams: null
  }
})
@Injectable()
export class BusinessTeamsState {
  constructor(private businessTeamsService: BusinessTeamsService) {
  }

  @Selector()
  static getBusinessTeamList(state: BusinessTeamsStateModel) {
    return state.businessTeams;
  }

  @Action(businessTeamsActions.AddBusinessTeam)
  addBusinessTeam({ getState, patchState }: StateContext<BusinessTeamsStateModel>, { payload }: businessTeamsActions.AddBusinessTeam) {
    return this.businessTeamsService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          businessTeams: [...state.businessTeams, result]
        });
      }));
  }

  @Action(businessTeamsActions.GetBusinessTeamList)
  getBusinessTeams({ getState, setState }:
                     StateContext<BusinessTeamsStateModel>, { businessUnitId }: businessTeamsActions.GetBusinessTeamList) {
    return this.businessTeamsService.fetchBusinessTeamsPerBusinessUnit(businessUnitId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          businessTeams: result,
        });
      }));
  }

  @Action(businessTeamsActions.GetOrganizationBusinessTeamList)
  getBusinessUnitsPerOrganization({ getState, setState }:
                     StateContext<BusinessTeamsStateModel>, { orgId }: businessTeamsActions.GetOrganizationBusinessTeamList) {
    return this.businessTeamsService.fetchBusinessTeamsPerOrganization(orgId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          businessTeams: result,
        });
      }));
  }


  @Action(businessTeamsActions.UpdateBusinessTeam)
  updateBusinessTeam({ getState, setState }: StateContext<BusinessTeamsStateModel>, { payload }: businessTeamsActions.UpdateBusinessTeam) {
    return this.businessTeamsService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const unitsList = [...state.businessTeams];
        const orgIndex = unitsList.findIndex(item => item.id === payload.id);
        unitsList[orgIndex] = payload;
        setState({
          ...state,
          businessTeams: unitsList,
        });
      }));
  }


  @Action(businessTeamsActions.DeleteBusinessTeam)
  deleteBusinessTeam({ getState, setState }: StateContext<BusinessTeamsStateModel>, { payload }: businessTeamsActions.DeleteBusinessTeam) {
    return this.businessTeamsService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.businessTeams.filter(item => item.id !== payload.id);
      setState({
        ...state,
        businessTeams: filteredArray,
      });
    }));
  }

}

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as ContactActions from '../../contact.actions';
import { Contact } from '../../models/contact.modul';


@Component({
  selector: 'app-contact-edit-dialog',
  templateUrl: './contact-edit-dialog.component.html',
  styleUrls: ['./contact-edit-dialog.component.scss']
})
export class ContactEditDialogComponent implements OnInit {
  editContactForm: FormGroup;
  email = new FormControl('');
  name = new FormControl('');
  title = new FormControl('');
  phone = new FormControl('');
  address = new FormControl('');
  state = new FormControl('');
  country = new FormControl('');
  staffCode = new FormControl('');
  note = new FormControl('');
  active = new FormControl('');


  constructor(public dialogRef: MatDialogRef<ContactEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: Contact) {
  }


  ngOnInit() {
    this.editContactForm = this.formBuilder.group({
      email: this.email,
      name: this.name,
      title: this.title,
      phone: this.phone,
      address: this.address,
      state: this.state,
      country: this.country,
      staff_code: this.staffCode,
      note: this.note,
      active: this.active
    });
  }


  submit(): void {
    this.store.dispatch(new ContactActions.UpdateContact(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editContactForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({backend: beError.defaultMessage});
      });
    }

    return NEVER;
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { Contact } from '../models/contact.modul';
import { Organization } from '../../organizations/models/organization.model';

const CONTACT_API = '/api/contact';

@Injectable()
export class ContactService {


  constructor(private http: HttpClient) {
  }

  addRow(item: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${CONTACT_API}`, {...item});
  }

  updateRow(updateItem: Contact): Observable<Contact> {
    return this.http.patch<Contact>(`${CONTACT_API}`, {...updateItem});
  }

  deleteRow = (item: Contact): Observable<any> =>
    this.http.delete(`${CONTACT_API}/${item.id}`)

  getRow(id: string): Observable<Contact> {
    return this.http.get<Contact>(`${CONTACT_API}/${id}`);
  }

  fetchUsersPerOrganization(orgId: string): Observable<Contact[]> {
    const fetchEnpoint = `${CONTACT_API}/org/${orgId}`;
    return this.http.get<Contact[]>(fetchEnpoint);
  }

  fetchUsersPerBusinessGroup(id: string): Observable<Contact[]> {
    const fetchEnpoint = `${CONTACT_API}/bg/${id}`;
    return this.http.get<Contact[]>(fetchEnpoint);
  }
}

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Service } from '../../models/services.model';
import * as servicesActions from '../../services.actions';


@Component({
  selector: 'app-service-edit-dialog',
  templateUrl: './service-edit-dialog.component.html',
  styleUrls: ['./service-edit-dialog.component.scss']
})
export class ServiceEditDialogComponent implements OnInit {

  editServiceForm: FormGroup;
  name = new FormControl('', Validators.required);

  constructor(public dialogRef: MatDialogRef<ServiceEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: Service) {
  }

  ngOnInit() {
    this.editServiceForm = this.formBuilder.group({
      name: this.name,
    });
  }

  submit() {
    this.store.dispatch(new servicesActions.UpdateService(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editServiceForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({backend: beError.defaultMessage});
      });
    }

    return NEVER;
  }
}

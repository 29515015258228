import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';


import { UpdateOrganization } from '../../organizations.actions';
import { Organization } from '../../models/organization.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-org-edit-dialog',
  templateUrl: './org-edit-dialog.component.html',
  styleUrls: ['./org-edit-dialog.component.scss']
})
export class OrgEditDialogComponent implements OnInit {
  editOrgForm: FormGroup;
  name = new FormControl('', Validators.required);
  type = new FormControl('', Validators.required);
  code = new FormControl('');
  active = new FormControl('', Validators.required);
  evalEnabled = new FormControl('');
  cmEnabled = new FormControl('');
  rmpEnabled = new FormControl('');
  csaBroker = new FormControl('');
  aggregator = new FormControl('');
  publishOnUpload = new FormControl('');
  note = new FormControl('');

  constructor(public dialogRef: MatDialogRef<OrgEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: Organization) {
  }

  ngOnInit() {
    this.editOrgForm = this.formBuilder.group({
      name: this.name,
      type: this.type,
      code: this.code,
      active: this.active,
      evalEnabled: this.evalEnabled,
      cmEnabled: this.cmEnabled,
      rmpEnabled: this.rmpEnabled,
      csaBroker: this.csaBroker,
      aggregator: this.aggregator,
      publishOnUpload: this.publishOnUpload,
      note: this.note
    });
    if (this.data?.businessGroupsAvailable) {
      this.editOrgForm.controls.type.disable();
    }
  }


  submit() {
    this.store.dispatch(new UpdateOrganization(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editOrgForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    return NEVER;
  }
}

import { User } from './models/users.model';

export class AddUser {
  static readonly type = '[User] Add';

  constructor(public payload: User) {
  }
}

export class AddCsaUser {
  static readonly type = '[User] Add Csa';

  constructor(public payload: User) {
  }
}

export class GetUsers {
  static readonly type = '[User] Get List';
  constructor(public organizationId: string) {
  }
}

export class GetCsaUsers {
  static readonly type = '[User] Get CsaUsers List';
}

export class UpdateUser {
  static readonly type = '[User] Update';

  constructor(public payload: User) {
  }
}

export class DeleteUser {
  static readonly type = '[User] Delete';

  constructor(public payload: User) {
  }
}

export class SetSelectedUser {
  static readonly type = '[User] Set Selected';

  constructor(public payload: User) {
  }
}

export class GetUser {
  static readonly type = '[User] Get Item';
  constructor(public id: string) {
  }
}

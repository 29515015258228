import { GetContacts } from './../../contact.actions';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { NEVER, Observable, Subject, combineLatest } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { catchError, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import { AddContact, DeleteContact, GetContact } from '../../contact.actions';

import { ContactState } from '../../contact.state';
import { Contact } from '../../models/contact.modul';
import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';

import { ContactEditDialogComponent } from '../contact-edit-dialog/contact-edit-dialog.component';
import * as ContactActions from '../../contact.actions';
import * as organizationsActions from '../../../organizations/organizations.actions';
import * as groupsActions from '../../../business-groups/groups.actions';
import { GroupsState } from '../../../business-groups/groups.state';
import { Group } from '../../../business-groups/models/group.model';


@Component({
  selector: 'app-contact-list-page',
  templateUrl: './contact-list-page.component.html',
  styleUrls: ['./contact-list-page.component.scss']
})
export class ContactListPageComponent implements OnInit {
  addContactForm: FormGroup;
  email = new FormControl('');
  name = new FormControl('');
  title = new FormControl('');
  phone = new FormControl('');
  address = new FormControl('');
  state = new FormControl('');
  country = new FormControl('');
  staffCode = new FormControl('');
  note = new FormControl('');
  active = new FormControl('');
  editing = {};
  rows = {};
  isLoading = false;
  organizationId: string;
  datasource = null;
  currentOrganization: Organization;
  businessGroupId: string;

  @Select(ContactState.getContactList) contactList$: Observable<Contact[]>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;
  @Select(GroupsState.getSelectedGroup) group$: Observable<Group>;
  public ngUnsubscribe = new Subject();

  constructor(protected router: Router,
              private formBuilder: FormBuilder,
              private activeRoute: ActivatedRoute,
              private store: Store,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {
    this.isLoading = true;

  }

  ngOnInit() {

    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.businessGroupId = params.get('businessGroupId');
        this.store.dispatch(new GetContacts(this.businessGroupId));
        this.store.dispatch(new groupsActions.GetGroup(this.businessGroupId));
      });

    this.addContactForm = this.formBuilder.group({
      email: this.email,
      name: this.name,
      title: this.title,
      phone: this.phone,
      address: this.address,
      state: this.state,
      country: this.country,
      staff_code: this.staffCode,
      note: this.note,
      orgId: this.organizationId,
      active: this.active,
      bgId: this.businessGroupId
    });

    this.org$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((org: Organization) => {
        if (!org && this.organizationId) {
          this.store.dispatch(new organizationsActions.GetOrganization(this.organizationId))
        }
        if (org) {
          this.currentOrganization = org;
        }
      });

    this.contactList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((item) => {
        this.rows = item;
        this.datasource = this.rows;
        this.isLoading = false;
      });

      combineLatest([this.group$, this.org$]).pipe(
        takeUntil(this.ngUnsubscribe)
      )
        .subscribe(([group, org]) => {
          this.setBreadcrumbs(group, org);
        });

  }

  addContact(contactForm: NgForm): void {
    this.addContactForm.value.organizationId = this.organizationId;
    this.store.dispatch(new AddContact(this.addContactForm.value))
      .subscribe((item) => {
        this.addContactForm.reset({ active: true });
        contactForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });

  }

  deleteContact(contact: Contact) {
    this.store.dispatch(new DeleteContact(contact))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }


  startEdit(row: Contact) {
    const dialogRef = this.dialog.open(ContactEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.store.dispatch(new ContactActions.UpdateContact(result))
            .pipe(catchError(error => this.onServerErrorHandling(error)))
            .subscribe(() => {
              this.toast.setMessage('item updated successfully.', 'success');
            });
        }

      });
  }

  onServerErrorHandling(error) {
    this.toast.setMessage(error.error && error.error.message, 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentGroup: Group, currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: `Organizations - ${currentOrganization?.name}`, url: `../../../../list`, params: [] },
        {
          label: `Business Groups`,
          url: `../../`,
          params: {}
        },
        {
          label: `${ currentGroup?.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }
}

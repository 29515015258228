import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BusinessUnitsModule } from '../business-units/business-units.module';
import { ServicesModule } from '../services/services.module';
import { ContactModule } from '../contacts/contact.module';

@NgModule({
  imports: [RouterModule.forChild([
/*    {
      path: ':businessGroupId/businessUnit',
      component: BusinessUnitsPageComponent,
    },*/
    { path: ':orgId/hierarchy', loadChildren: () => ServicesModule },
    { path: ':orgId/hierarchy', loadChildren: () => BusinessUnitsModule },
    { path: ':orgId/hierarchy', loadChildren: () => ContactModule }
  ])],
  exports: [RouterModule],
  providers: []
})
export class BusinessUnitsRoutingModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';

import { Service } from '../models/services.model';

const SERVICE_API = '/api/service';

@Injectable()
export class ServicesService {


  constructor(private http: HttpClient) {
  }


  fetchServicesPerBusinessGroup(bgId: string): Observable<Service[]> {
    const fetchEnpoint = `${SERVICE_API}/businessGroup/${bgId}/list`;
    return this.http.get<Service[]>(fetchEnpoint);
  }

  getRow(item: Service): Observable<Service> {
    const fetchEndpoint = `${SERVICE_API}/${item.id}`;
    return this.http.get<Service>(fetchEndpoint);
  }

  addRow(item: Service): Observable<Service> {
    const endpoint = `${SERVICE_API}`;
    return this.http.post<Service>(endpoint, { ...item });
  }

  updateRow(updateItem: Service): Observable<Service> {
    const endpoint = `${SERVICE_API}`;

    return this.http.patch<Service>(endpoint, { ...updateItem });
  }

  deleteRow(item: Service): Observable<any> {
    const endpoint = `${SERVICE_API}/${item.id}`;

    return this.http.delete(endpoint);
  }

}

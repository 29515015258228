import { Group } from './models/group.model';

export class AddGroup {
  static readonly type = '[Group] Add';

  constructor(public payload: Group) {
  }
}

export class GetGroups {
  static readonly type = '[Group] Get list';
  constructor(public organizationId: string) {
  }
}

export class GetGroup {
  static readonly type = '[Group] Get item';
  constructor(public groupId: string) {
  }
}

export class UpdateGroup {
  static readonly type = '[Group] Update';

  constructor(public payload: Group) {
  }
}

export class DeleteGroup {
  static readonly type = '[Group] Delete';

  constructor(public payload: Group) {
  }
}

export class SetSelectedGroup {
  static readonly type = '[Group] Set';

  constructor(public payload: Group) {
  }
}

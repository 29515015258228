import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { combineLatest, NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { MatDialog } from '@angular/material/dialog';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import * as groupsActions from '../../../business-groups/groups.actions';
import * as orgsActions from '../../../organizations/organizations.actions';
import * as businessUnitActions from '../../../business-units/business-units.actions';
import { GroupsState } from '../../../business-groups/groups.state';
import { Group } from '../../../business-groups/models/group.model';
import { BusinessUnit } from '../../models/business-unit.model';
import { BusinessUnitsState } from '../../business-units.state';
import { BusinessUnitEditDialogComponent } from '../business-unit-edit-dialog/business-unit-edit-dialog.component';
import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';

@Component({
  selector: 'app-business-units-page',
  templateUrl: './business-units-page.component.html',
  styleUrls: ['./business-units-page.component.scss']
})
export class BusinessUnitsPageComponent implements OnInit {
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  addUnitForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('', Validators.required);
  editing = {};
  rows = [];
  datasource = [];
  isLoading = true;
  organizationId: string;
  businessGroupId: string;
  group: Group;

  @Select(BusinessUnitsState.getBusinessUnitList) businessUnitList$: Observable<BusinessUnit[]>;
  @Select(GroupsState.getSelectedGroup) group$: Observable<Group>;
  @Select(OrganizationState.getSelectedOrganization) org$: Observable<Organization>;

  public ngUnsubscribe = new Subject();

  constructor(private formBuilder: FormBuilder,
              private store: Store,
              private activeRoute: ActivatedRoute,
              protected router: Router,
              public dialog: MatDialog,
              private breadcrumbsService: NgxBreadcrumbsService,
              public toast: ToastComponent) {
    this.addUnitForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      active: new FormControl(true)
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.activeRoute.paramMap
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((params) => {
        this.organizationId = params.get('orgId');
        this.businessGroupId = params.get('businessGroupId');
        this.store.dispatch(new orgsActions.GetOrganization(this.organizationId));
        this.store.dispatch(new groupsActions.GetGroup(this.businessGroupId));

      });


    this.businessUnitList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });

    combineLatest([this.group$, this.org$]).pipe(
      takeUntil(this.ngUnsubscribe)
    )
      .subscribe(([group, org]) => {
        this.setBreadcrumbs(group, org);
      });

    this.group$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((item: Group) => {
        this.group = item;
        if (this.group) {
          this.store.dispatch(new businessUnitActions.GetBusinessUnitList(this.group.id));
        }
      });

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  addBusinessUnit(unitForm: NgForm): void {
    this.addUnitForm.value.businessGroupId = this.businessGroupId;

    this.store.dispatch(new businessUnitActions.AddBusinessUnit(this.addUnitForm.value))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addUnitForm)))
      .subscribe((item) => {
        this.addUnitForm.reset({ active: true });
        unitForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }


  startEdit(row: BusinessUnit) {
    const dialogRef = this.dialog.open(BusinessUnitEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }


  deleteUnit(row: BusinessUnit) {
    this.store.dispatch(new businessUnitActions.DeleteBusinessUnit(row))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.addUnitForm)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }

  navigateToBusinessTeam(row: BusinessUnit) {
    this.store.dispatch(new businessUnitActions.SetSelectedBusinessUnit(row));
    this.router.navigate(['./', `${row.id}`, 'businessTeams'], { relativeTo: this.activeRoute });
  }


  onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({ backend: beError.defaultMessage });
      });
    }

    this.toast.setMessage('item failed to add', 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentGroup: Group, currentOrganization: Organization) {
    this.breadcrumbsService.store(
      [
        { label: `Organizations - ${currentOrganization && currentOrganization.name}`, url: `../../../../list`, params: [] },
        {
          label: `Business Groups`,
          url: `../../`,
          params: {}
        },
        {
          label: `${ currentGroup && currentGroup.name}`,
          url: `./`,
          params: {}
        }
      ] as any);
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthUser } from '../models/auth.model';
import { ProfileService } from './profile.service';
import { SetPasswordRequest } from '../models/password.model';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
              public profileService: ProfileService) {
  }

  logout = (): Observable<any> =>
    this.http.post('/api/logout', null)

  loginUser = (email: string, password: string): Observable<any> => {
     return of( { email, password });
/*    return this.http.post<AuthUser>('/api/login', { email, password }, { observe: 'response' })
      .pipe(
        map((response) => {
          const token = response.headers.get('eval_token');
          console.log( response.body);
          this.profileService.setUserData(token);

          return response.body;
        })
      );*/
  }

  loginSsoUser = (idToken: string, accessToken: string): Observable<any> => {
    return this.http.post<AuthUser>('/api/sso-login', { idToken }, { observe: 'response' })
      .pipe(
        map((response) => {
          const token = response.headers.get('brs_token');
          this.profileService.setUserData(token, idToken, accessToken, true);
          return response.body;
        })
      );
  }

  resetPassword = (email: string): Observable<any> =>
    this.http.post<AuthUser>('/api/password/reset', { email })

  setNewPassword = (model: SetPasswordRequest): Observable<any> =>
    this.http.post<AuthUser>('api/password/activate', model)

  checkPasswordToken = (token: string): Observable<any> =>
    this.http.get(`api/password/verify?token=${token}`)


  replaceUrl(url) {
    window.location.replace(url);
  }

}

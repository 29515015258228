import { BusinessUnit } from './models/business-unit.model';

export class AddBusinessUnit {
  static readonly type = '[BusinessUnit] Add';

  constructor(public payload: BusinessUnit) {
  }
}

export class GetBusinessUnitList {
  static readonly type = '[BusinessUnit] Get Business Group Unit List';
  constructor(public businessGroupId: string) {
  }
}

export class GetOrganizationBusinessUnitList {
  static readonly type = '[BusinessUnit] Get Organization Units List';
  constructor(public orgId: string) {
  }
}

export class GetBusinessUnit {
  static readonly type = '[BusinessUnit] Get Item';
  constructor(public businessUnitId: string) {
  }
}

export class UpdateBusinessUnit {
  static readonly type = '[BusinessUnit] Update';

  constructor(public payload: BusinessUnit) {
  }
}

export class DeleteBusinessUnit {
  static readonly type = '[BusinessUnit] Delete';

  constructor(public payload: BusinessUnit) {
  }
}

export class SetSelectedBusinessUnit {
  static readonly type = '[BusinessUnit] Set';

  constructor(public payload: BusinessUnit) {
  }
}

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { NEVER, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as userActions from '../../users.actions';
import { User } from '../../models/users.model';


@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.scss']
})
export class UserEditDialogComponent implements OnInit {
  editUserForm: FormGroup;
  email = new FormControl('', Validators.required);


  constructor(public dialogRef: MatDialogRef<UserEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: User) {
  }


  ngOnInit() {
    this.editUserForm = this.formBuilder.group({
      email: this.email,
    });
  }


  submit(): void {
    this.store.dispatch(new userActions.UpdateUser(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editUserForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({backend: beError.defaultMessage});
      });
    }

    return NEVER;
  }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Organization } from '../models/organization.model';

@Injectable()
export class OrganizationsService {

  constructor(private http: HttpClient) {
  }

  getOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>('/api/organization/list');
  }

  countOrganizations(): Observable<number> {
    return this.http.get<number>('/api/organizations/count');
  }

  addOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>('/api/organization', organization);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(`/api/organization/${organizationId}`);
  }

  editOrganization(organization: Organization): Observable<any> {
    return this.http.patch(`/api/organization`, organization);
  }

  deleteOrganization(organization: Organization): Observable<any> {
    return this.http.delete(`/api/organization/${organization.id}`);
  }

}

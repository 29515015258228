import { Component } from '@angular/core';


@Component({
  selector: 'app-hierarchy-page',
  templateUrl: './hierarchy-page.component.html',
  styleUrls: ['./hierarchy-page.component.scss']
})
export class HierarchyPageComponent  {

}

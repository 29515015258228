import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';
import { NgxsModule } from '@ngxs/store';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { HierarchyRoutingModule } from './hierarchy-routing.module';
import { ServicesModule } from '../services/services.module';
import { BusinessUnitsModule } from '../business-units/business-units.module';
import { BusinessGroupsModule } from '../business-groups/business-groups.module';

/* Services */

/* States */

/* Components */
import { HierarchyPageComponent } from './components/hierarchy-page/hierarchy-page.component';
import { BusinessTeamsModule } from '../business-teams/business-teams.module';


@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    HierarchyRoutingModule,
    NgxsModule.forFeature([
    ]),
    ServicesModule,
    BusinessUnitsModule,
    BusinessGroupsModule,
    BusinessTeamsModule,
    SharedModule
  ],
  declarations: [
    HierarchyPageComponent,
  ],
  providers: [
  ],
  exports: []
})
export class HierarchyModule {
}

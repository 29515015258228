import { AuthRoutingModule } from './auth-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { LogoutComponent } from './components/logout/logout.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { LoginGuard } from './services/login-guard.service';
import { ProfileService } from './services/profile.service';
import { MaterialModule } from '../../material.module';
import { AppConfigService } from './services/app-config.service';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SsoLoginPageComponent } from './components/sso-login-page/sso-login-page.component';
import { OpenidSsoService } from './services/openid-sso.service';

import { OAuthModule } from 'angular-oauth2-oidc';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthorizePageComponent } from './components/authorize-page/authorize-page.component';
import { XsrfInterceptor } from './interceptors/xsrf.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TokenExpiredInterceptor } from './interceptors/tokenExpired.interceptor';
import { JwtTokenService } from './services/jwt-token.service';
import { AuthTemplateComponent } from './components/auth-template/auth-template.component';


export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

@NgModule({
  imports: [

    AuthRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OAuthModule.forRoot(),
    JwtModule.forRoot({
      config: {
        headerName: 'brs_token',
        authScheme: '',
        tokenGetter: () => {
          return localStorage.getItem('brs_token');
        },
        whitelistedDomains: [/^null$/],
        blacklistedRoutes: []
      }
    })
  ],
  declarations: [
    LogoutComponent,
    LoginPageComponent,
    SsoLoginPageComponent,
    AuthorizePageComponent,
    AuthTemplateComponent
  ],
  providers: [
    AuthService,
    StorageService,
    LoginGuard,
    ProfileService,
    AppConfigService,
    OpenidSsoService,
    JwtTokenService,
   /*  { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true }, */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiredInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XsrfInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService]
    },
    { provide: 'Window', useValue: window }
  ],
  exports: [
    LoginPageComponent,
    LogoutComponent,
    SsoLoginPageComponent,
    AuthorizePageComponent,
    AuthTemplateComponent

  ],
})
export class AuthModule {
}

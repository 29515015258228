import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OrganizationsListPageComponent } from './components/organizations-list-page/organizations-list-page.component';
import { OrganizationsPageComponent } from './components/organizations-page/organizations-page.component';


@NgModule({
  imports: [RouterModule.forChild([
    { path: '', redirectTo: 'list', pathMatch: 'full' },
    {
      path: 'list', component: OrganizationsListPageComponent,
    },
  ])],
  exports: [RouterModule],
  providers: []
})
export class OrganizationsRoutingModule {
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';

import { BusinessUnit } from '../models/business-unit.model';

const BUSINESS_UNIT_API = '/api/businessUnit';

@Injectable()
export class BusinessUnitsService {


  constructor(private http: HttpClient) {
  }

  fetchBusinessUnitsPerBusinessGroups(businessGroupId: string): Observable<BusinessUnit[]> {
    const fetchEnpoint = `${BUSINESS_UNIT_API}/businessGroup/${businessGroupId}/list`;
    return this.http.get<BusinessUnit[]>(fetchEnpoint);
  }

  fetchBusinessUnitsPerOrganization(orgId: string): Observable<BusinessUnit[]> {
    const fetchEnpoint = `${BUSINESS_UNIT_API}/organization/${orgId}/list`;
    return this.http.get<BusinessUnit[]>(fetchEnpoint);
  }

  getRow(businessUnitId: string): Observable<BusinessUnit> {
    const fetchEndpoint = `${BUSINESS_UNIT_API}/${businessUnitId}`;
    return this.http.get<BusinessUnit>(fetchEndpoint);
  }

  addRow(item: BusinessUnit): Observable<BusinessUnit> {
    const endpoint = `${BUSINESS_UNIT_API}`;
    return this.http.post<BusinessUnit>(endpoint, { ...item });
  }

  updateRow(updateItem: BusinessUnit): Observable<BusinessUnit> {
    const endpoint = `${BUSINESS_UNIT_API}`;

    return this.http.patch<BusinessUnit>(endpoint, { ...updateItem });
  }

  deleteRow(item: BusinessUnit): Observable<any> {
    const endpoint = `${BUSINESS_UNIT_API}/${item.id}`;

    return this.http.delete(endpoint);
  }

}

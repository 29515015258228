import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';

import * as businessUnitsActions from './business-units.actions';
import { BusinessUnitsService } from './services/business-units.service';
import { BusinessUnit } from './models/business-unit.model';
import { GetOrganizationBusinessUnitList } from './business-units.actions';
import { GetBusinessUnitList } from './business-units.actions';

export interface BusinessUnitsStateModel {
  businessUnits: BusinessUnit[];
  selectedBusinessUnit: BusinessUnit;
}

@State<BusinessUnitsStateModel>({
  name: 'businessUnits',
  defaults: {
    businessUnits: null,
    selectedBusinessUnit: null
  }
})
@Injectable()
export class BusinessUnitsState {
  constructor(private businessUnitsService: BusinessUnitsService) {
  }

  @Selector()
  static getBusinessUnitList(state: BusinessUnitsStateModel) {
    return state.businessUnits;
  }

  @Selector()
  static getSelectedUnit(state: BusinessUnitsStateModel) {
    return state.selectedBusinessUnit;
  }

  @Action(businessUnitsActions.AddBusinessUnit)
  addBusinessUnit({ getState, patchState }: StateContext<BusinessUnitsStateModel>, { payload }: businessUnitsActions.AddBusinessUnit) {
    return this.businessUnitsService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          businessUnits: [...state.businessUnits, result]
        });
      }));
  }

  @Action(businessUnitsActions.GetBusinessUnit)
  getBusinessUnit({ getState, setState }: StateContext<BusinessUnitsStateModel>,{ businessUnitId }: businessUnitsActions.GetBusinessUnit) {
    return this.businessUnitsService.getRow(businessUnitId)
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            selectedBusinessUnit: result,
          });
        })
      );
  }

  @Action(businessUnitsActions.GetBusinessUnitList)
  getBusinessUnits({ getState, setState }:
                     StateContext<BusinessUnitsStateModel>, { businessGroupId }: businessUnitsActions.GetBusinessUnitList) {
    return this.businessUnitsService.fetchBusinessUnitsPerBusinessGroups(businessGroupId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          businessUnits: result,
        });
      }));
  }

  @Action(businessUnitsActions.GetOrganizationBusinessUnitList)
  getBusinessUnitsPerOrganization({ getState, setState }:
                     StateContext<BusinessUnitsStateModel>, { orgId }: businessUnitsActions.GetOrganizationBusinessUnitList) {
    return this.businessUnitsService.fetchBusinessUnitsPerOrganization(orgId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          businessUnits: result,
        });
      }));
  }


  @Action(businessUnitsActions.UpdateBusinessUnit)
  updateBusinessUnit({ getState, setState }: StateContext<BusinessUnitsStateModel>, { payload }: businessUnitsActions.UpdateBusinessUnit) {
    return this.businessUnitsService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const unitsList = [...state.businessUnits];
        const orgIndex = unitsList.findIndex(item => item.id === payload.id);
        unitsList[orgIndex] = payload;
        setState({
          ...state,
          businessUnits: unitsList,
        });
      }));
  }


  @Action(businessUnitsActions.DeleteBusinessUnit)
  deleteBusinessUnit({ getState, setState }: StateContext<BusinessUnitsStateModel>, { payload }: businessUnitsActions.DeleteBusinessUnit) {
    return this.businessUnitsService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.businessUnits.filter(item => item.id !== payload.id);
      setState({
        ...state,
        businessUnits: filteredArray,
      });
    }));
  }

  @Action(businessUnitsActions.SetSelectedBusinessUnit)
  setSelectedBusinessUnit({getState, setState}: StateContext<BusinessUnitsStateModel>,
                   { payload }: businessUnitsActions.SetSelectedBusinessUnit) {
    const state = getState();
    setState({
      ...state,
      selectedBusinessUnit: payload,
    });
  }

}

import { AfterViewChecked, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { NgxBreadcrumbsService } from 'ng8-breadcrumbs';
import { NEVER, Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import {
  catchError,
  distinctUntilChanged,
  filter,
  takeUntil,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { ToastComponent } from '../../../../shared/toast/toast.component';
import { AddUser, DeleteUser } from '../../users.actions';

import { UsersState } from '../../users.state';
import { User } from '../../models/users.model';
import { OrganizationState } from '../../../organizations/organizations.state';
import { Organization } from '../../../organizations/models/organization.model';

import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import * as usersActions from '../../users.actions';
import { CreateCsaUserComponent } from '../create-csa-user/create-csa-user.component';
import { DeleteConfirmationDialogComponent } from '../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss']
})
export class UsersListPageComponent implements OnInit, AfterViewChecked {
  addUserForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  editing = {};
  rows = [];
  isLoading = false;
  organizationId: string;
  datasource = null;
  currentOrganization: Organization;

  @Select(UsersState.getUserList) userList$: Observable<User[]>;
  @Select(OrganizationState.getSelectedOrganization)
  org$: Observable<Organization>;
  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;
  public ngUnsubscribe = new Subject();

  constructor(
    protected router: Router,
    private store: Store,
    public dialog: MatDialog,
    private breadcrumbsService: NgxBreadcrumbsService,
    public toast: ToastComponent
  ) {
    this.isLoading = true;
    this.store.dispatch(new usersActions.GetCsaUsers());
  }

  ngOnInit() {
    this.userList$
      .pipe(
        filter((x) => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((items) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });
  }

  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.table.recalculate();
  }

  addUser(userForm: NgForm): void {
    this.addUserForm.value.organizationId = this.organizationId;
    this.store
      .dispatch(new AddUser(this.addUserForm.value))
      .subscribe((item) => {
        this.addUserForm.reset({ active: true });
        userForm.resetForm({ active: true });
        this.toast.setMessage('item added successfully.', 'success');
      });
  }

  deleteUser(user: User) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '450px',
      height: '200px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'delete') {
        this.store
        .dispatch(new DeleteUser(user))
        .pipe(catchError((error) => this.onServerErrorHandling(error)))
        .subscribe(() => {
          this.toast.setMessage('item deleted successfully.', 'success');
        });
      }
    });
  }

  startEdit(row: User) {
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      data: { ...row }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store
          .dispatch(new usersActions.UpdateUser(result))
          .pipe(catchError((error) => this.onServerErrorHandling(error)))
          .subscribe(() => {
            this.toast.setMessage('item updated successfully.', 'success');
          });
      }
    });
  }

  onServerErrorHandling(error) {
    this.toast.setMessage(error.error && error.error.message, 'danger', 5000);
    return NEVER;
  }

  private setBreadcrumbs(currentOrganization: Organization) {
    this.breadcrumbsService.store([
      { label: 'Organizations', url: `../../list`, params: [] },
      {
        label: `${currentOrganization && currentOrganization.name}`,
        url: `./`,
        params: {},
      },
    ] as any);
  }

  createCsaUser() {
    const dialogRef = this.dialog.open(CreateCsaUserComponent, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toast.setMessage('item updated successfully.', 'success');
      }
    });
  }
}

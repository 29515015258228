import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileService } from '../../../auth/services/profile.service';
import { ApplicationInfo } from '../../../application-info';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public userInfo;
  public productTitle = 'Shared';
  public applicationInfo;


  constructor(protected router: Router,
              public profileService: ProfileService) {
    this.userInfo = this.profileService.getUser();
    this.applicationInfo = ApplicationInfo;

  }

  ngOnInit() {

  }

}

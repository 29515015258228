import { Service } from './models/services.model';

export class AddService {
  static readonly type = '[Service] Add';

  constructor(public payload: Service) {
  }
}

export class GetServices {
  static readonly type = '[Service] Get';
  constructor(public businessGroupId: string) {
  }
}

export class UpdateService {
  static readonly type = '[Service] Update';

  constructor(public payload: Service) {
  }
}

export class DeleteService {
  static readonly type = '[Service] Delete';

  constructor(public payload: Service) {
  }
}

export class SetSelectedService {
  static readonly type = '[Service] Set';

  constructor(public payload: Service) {
  }
}

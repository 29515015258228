import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, } from 'rxjs';
import { User } from '../models/users.model';
import { Organization } from '../../organizations/models/organization.model';

const USER_API = '/api/user';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) {
  }

  fetchUsersPerOrganization(orgId: string): Observable<User[]> {
    const fetchEnpoint = `${USER_API}/organization/${orgId}`;
    return this.http.get<User[]>(fetchEnpoint);
  }

  addRow(item: User): Observable<User> {
    return this.http.post<User>(`${USER_API}`, {...item});
  }

  addCsaRow(item: User): Observable<User> {
    return this.http.post<User>(`${USER_API}/csa`, {...item});
  }

  updateRow(updateItem: User): Observable<User> {
    return this.http.patch<User>(`${USER_API}`, {...updateItem});
  }

  deleteRow = (item: User): Observable<any> =>
    this.http.delete(`${USER_API}/${item.id}`)

  getRow(id: string): Observable<User> {
    return this.http.get<User>(`${USER_API}/${id}`);
  }

  getCsaUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${USER_API}/getCsaUsers`);
  }

  uploadServiceProvider(formData : FormData): Observable<any> {
    return this.http.post(`/api/organization/upload/serviceProvider`, formData);
  }
}

import { Injectable } from '@angular/core';

import { AuthUser } from '../models/auth.model';
import { ROLES } from '../constants/roles.constant';
import {StorageService} from './storage.service';

const DEFAULT_HOMEPAGE = {
  [ROLES.CLIENT_ADMIN]: '/main',
  [ROLES.USER]: '/main/help'
};

@Injectable()
export class ProfileService {

  constructor(public storage: StorageService) {
  }

  setUserData(token: string, idToken?: string, accessToken?: string, isSsoLogin = false): void {
    this.storage.setToken(token);

    this.storage.saveObject('access_token', accessToken);
    this.storage.saveObject('id_token', idToken);
    this.storage.saveObject('is-sso-login', isSsoLogin);
  }

  removeUserData(): void {
    this.storage.removeToken();
    this.storage.removeXsrf();
  }

  isLoggedIn(): boolean {
    return !!this.storage.getUser();
  }

  getDefaultHomePage(): string {
    const defaultPage = DEFAULT_HOMEPAGE[this.getUserCurrentRole()] || '/main/help';
    console.log('defaultPage ::'+defaultPage);
    return defaultPage;
  }

  getUserCurrentRole() {
    if ( this.storage.getUser() != null) {
      return this.storage.getUser().roles[0].name || 'USER';
    }
  }

  getUserRoles(): Array<string> {
    let roles = this.storage.getUser().roles;
    console.log('roles ::'+roles);
    return roles.filter(e => e !== ROLES.USER);
  }

  getUser(): AuthUser {
    return this.storage.getUser();
  }

  isHasPermission(allowedRoles): boolean {
    const currentUserRoles = this.getUserRoles();
    return true;
  }

  isCsaAdmin(): boolean {
    let roles = this.getUserCurrentRole();
    return roles === ROLES.CLIENT_ADMIN;
  }

  setActiveRole(role): void {
    this.storage.saveObject('activeRole', role);
  }

  getActiveRole() {
    let activeRole = this.storage.getObject('activeRole');

    return this.isHasPermission([activeRole]) && activeRole || 'USER';
  }
}

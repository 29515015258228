import { Injectable } from '@angular/core';
import { JwtTokenService } from './jwt-token.service';

@Injectable()
export class StorageService {
  private readonly tokenKey = 'brs_token';
  private readonly xsrfKey = 'XSRF-TOKEN';
  user = null;
  constructor( public jwtService: JwtTokenService ) { }

  setToken(token) {
    localStorage.setItem(this.tokenKey, token);
  }

  getToken() {
    return localStorage.getItem(this.tokenKey);
  }

  removeToken() {
    localStorage.removeItem(this.tokenKey);
  }

  setXsrf(token) {
    localStorage.setItem(this.xsrfKey, token);
  }

  getXsrf() {
    return localStorage.getItem(this.xsrfKey);
  }

  removeXsrf() {
    localStorage.removeItem(this.xsrfKey);
  }

  getUser() {
    return this.jwtService.getTokenData(this.getToken());
  }
  setUser(user) {
     this.user = user;
  }

  saveObject(name, obj) {
    localStorage.setItem(`${this.getUser().userId}_${name}`, JSON.stringify(obj));
  }

  getObject(name) {
    return JSON.parse(localStorage.getItem(`${this.getUser().userId}_${name}`));
  }

  cleanObject(name) {
    return localStorage.removeItem(`${this.getUser().userId}_${name}`);
  }

  cleanStorage() {
    localStorage.clear();
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';
import { NgxsModule } from '@ngxs/store';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { BusinessUnitsRoutingModule } from './business-groups-routing.module';
import { BusinessTeamsModule } from '../business-teams/business-teams.module';

/* Services */
import { GroupsService } from './services/groups.service';

/* States */
import { GroupsState } from './groups.state';

/* Components */
import {
  BusinessGroupsListViewComponent,
} from './components/business-groups-list-view/business-groups-list-view.component';
import { BusinessGroupsEditDialogComponent } from './components/business-group-edit-dialog/business-groups-edit-dialog.component';


@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      GroupsState
    ]),
    SharedModule,
    BusinessTeamsModule,
    BusinessUnitsRoutingModule
  ],
  declarations: [
    BusinessGroupsEditDialogComponent,
    BusinessGroupsListViewComponent,
  ],
  providers: [GroupsService],
  exports: [BusinessGroupsListViewComponent]
})
export class BusinessGroupsModule {
}

import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import * as groupsActions from './groups.actions';
import { Group } from './models/group.model';
import { GroupsService } from './services/groups.service';

export interface GroupStateModel {
  groups: Group[];
  selectedGroup: Group;
}

@State<GroupStateModel>({
  name: 'groups',
  defaults: {
    groups: null,
    selectedGroup: null
  }
})
@Injectable()
export class GroupsState {
  constructor(private groupsService: GroupsService) {
  }

  @Selector()
  static getGroupList(state: GroupStateModel) {
    return state.groups;
  }

  @Selector()
  static getSelectedGroup(state: GroupStateModel) {
    return state.selectedGroup;
  }

  @Action(groupsActions.AddGroup)
  addGroup({getState, patchState}: StateContext<GroupStateModel>, {payload}: groupsActions.AddGroup) {
    return this.groupsService.addRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        patchState({
          groups: [...(state.groups || []), result]
        });
      }));
  }

  @Action(groupsActions.GetGroups)
  getGroups({getState, setState}: StateContext<GroupStateModel>, {organizationId}: groupsActions.GetGroups) {
    return this.groupsService.fetchGroupsPerOrganization(organizationId)
      .pipe(tap((result) => {
        const state = getState();
        setState({
          ...state,
          groups: result,
        });
      }));
  }

  @Action(groupsActions.GetGroup)
  getGroup({getState, setState}: StateContext<GroupStateModel>, {groupId}: groupsActions.GetGroup) {
    return this.groupsService.getRow(groupId)
      .pipe(
        tap((result) => {
          const state = getState();
          setState({
            ...state,
            selectedGroup: result,
          });
        })
      );
  }


  @Action(groupsActions.UpdateGroup)
  updateGroup({getState, setState}: StateContext<GroupStateModel>, {payload}: groupsActions.UpdateGroup) {
    return this.groupsService.updateRow(payload)
      .pipe(tap((result) => {
        const state = getState();
        const orgList = [...state.groups];
        const orgIndex = orgList.findIndex(item => item.id === payload.id);
        orgList[orgIndex] = payload;
        setState({
          ...state,
          groups: orgList,
        });
      }));
  }


  @Action(groupsActions.DeleteGroup)
  deleteGroup({getState, setState}: StateContext<GroupStateModel>, {payload}: groupsActions.DeleteGroup) {
    return this.groupsService.deleteRow(payload).pipe(tap(() => {
      const state = getState();
      const filteredArray = state.groups.filter(item => item.id !== payload.id);
      setState({
        ...state,
        groups: filteredArray,
      });
    }));
  }

  @Action(groupsActions.SetSelectedGroup)
  setSelectedGroup({getState, setState}: StateContext<GroupStateModel>,
                   { payload }: groupsActions.SetSelectedGroup) {
    const state = getState();
    setState({
      ...state,
      selectedGroup: payload,
    });
  }

}

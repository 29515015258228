import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LogoutComponent } from './app-modules/auth/components/logout/logout.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { OrganizationsModule } from './feature-modules/organizations/organizations.module';
import { UsersModule } from './feature-modules/users/users.module';
import { ContactModule } from './feature-modules/contacts/contact.module';
import { HierarchyModule } from './feature-modules/hierarchy/hierarchy.module';
import { OrganizationsPageComponent } from './feature-modules/organizations/components/organizations-page/organizations-page.component';
import { PageUnavailableComponent } from './components/page-unavailable/page-unavailable.component';
import { SsoLoginPageComponent } from './app-modules/auth/components/sso-login-page/sso-login-page.component';
import { AuthorizePageComponent } from './app-modules/auth/components/authorize-page/authorize-page.component';
import { LoginGuard } from './app-modules/auth/services/login-guard.service';

export function loadOrganizationsModule() {
  return OrganizationsModule;
}

export function loadUsersModule() {
  return UsersModule;
}

export function loadContactModule() {
  return ContactModule;
}

export function loadHierarchyModule() {
  return HierarchyModule;
}

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main',
    component: HomeComponent,
    canActivate: [LoginGuard],
    children: [
      { path: '', redirectTo: 'organizations', pathMatch: 'full' },
      {
        path: 'organizations',
        component: OrganizationsPageComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: '', loadChildren: () => OrganizationsModule },
          { path: '', loadChildren: () => UsersModule },
          { path: '', loadChildren: () => HierarchyModule },
        ]
      },

    ]
  },
  { path: 'sso-login', component: SsoLoginPageComponent },
  { path: 'authorize', component: AuthorizePageComponent },
  { path: 'login', component: SsoLoginPageComponent },
  { path: 'page-unavailable', component: PageUnavailableComponent },

  { path: 'logout', component: LogoutComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

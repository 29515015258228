import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HierarchyPageComponent } from './components/hierarchy-page/hierarchy-page.component';
import { ServicesModule } from '../services/services.module';
import { BusinessUnitsModule } from '../business-units/business-units.module';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: ':orgId/hierarchy',
      component: HierarchyPageComponent,
    },
  /*  { path: ':orgId/hierarchy', loadChildren: () => ServicesModule },
    { path: ':orgId/hierarchy', loadChildren: () => BusinessUnitsModule },*/
  ])],
  exports: [RouterModule],
  providers: []
})
export class HierarchyRoutingModule {
}

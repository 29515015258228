import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ServicesPageComponent } from './components/services-page/services-page.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: ':businessGroupId/services',
      component: ServicesPageComponent,
    },
  ])],
  exports: [RouterModule],
  providers: []
})
export class ServicesRoutingModule {
}

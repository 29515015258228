import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../models/env.model';

@Injectable()
export class AppConfigService {

  public data: Environment;

  constructor(private http: HttpClient) {
  }

  getEnvData(): Environment {
    return this.data;
  }

  load(): Promise<any> {

    const promise = this.http.get('../../../../assets/env.json')
      .toPromise()
      .then((data: Environment) => {
        this.data = data;
        return data;
      });

    return promise;
  }
}

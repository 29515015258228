import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

// import { StorageService } from '../..//storage/storage.service';
import { OpenidSsoService } from '../services/openid-sso.service';
import { StorageService } from '../services/storage.service';
import { AppConfigService } from '../services/app-config.service';


@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    protected storage: StorageService,
    public openIdSsoService: OpenidSsoService,
    public configService: AppConfigService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.openIdSsoService.isSsoRequest(req)) {
      return next.handle(req);
    }
    let apiEndPoint = '';
    if (!req.url.includes('env.json')) {
      const appConfig = this.configService.getEnvData();
      const apiHostUrl =  appConfig.apiHostUrl;
      apiEndPoint = apiHostUrl + `${req.url}`;
    } else {
      apiEndPoint = `${req.url}`;
    }
    const apiReq = req.clone({ url: apiEndPoint });
    return this.interceptXsrfTokenHandler(apiReq, next);
  }

  private interceptXsrfTokenHandler(req: HttpRequest<any>, next: HttpHandler, retriesCount: number = 5): Observable<HttpEvent<any>> {
    let requestToForward = req;
    let token = this.tokenExtractor.getToken() as string;
    if (token !== null) {
      requestToForward = req.clone({ setHeaders: { 'X-XSRF-TOKEN': token } });
    }

    return next.handle(requestToForward)
      .pipe(
        tap(_response => {
          token = this.tokenExtractor.getToken() as string;
          if (token !== null) {
            this.storage.setXsrf(token);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error.status === 403 && error.error.message.includes('Invalid CSRF Token') && retriesCount > 0) {
            return this.interceptXsrfTokenHandler(requestToForward, next, --retriesCount);
          }
          return throwError(error);
        })
      );
  }
}

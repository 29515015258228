import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtTokenService {

    constructor(public jwtHelper: JwtHelperService) { }

    getTokenData(token) {
        return this.jwtHelper.decodeToken(token);
    }

    getTokenExpirationDate(token) {
        return this.jwtHelper.getTokenExpirationDate(token);
    }

    isTokenExpired(token) {
        return this.jwtHelper.isTokenExpired(token);
    }
}

import { Organization } from './models/organization.model';

export class AddOrganization {
  static readonly type = '[Organization] Add';

  constructor(public payload: Organization) {
  }
}

export class GetOrganizations {
  static readonly type = '[Organization] Get List';
}
export class GetOrganization {
  static readonly type = '[Organization] Get Item';
  constructor(public orgId: string) {
  }
}

export class UpdateOrganization {
  static readonly type = '[Organization] Update';

  constructor(public payload: Organization) {
  }
}

export class DeleteOrganization {
  static readonly type = '[Organization] Delete';

  constructor(public payload: Organization) {
  }
}

export class SetSelectedOrganization {
  static readonly type = '[Organization] Set';

  constructor(public payload: Organization) {
  }
}

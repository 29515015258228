import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { NEVER, Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Group } from '../../models/group.model';
import * as groupsActions from '../../groups.actions';
import { GROUP_CURRENCY } from '../../constants/currency.constant';


@Component({
  selector: 'app-business-groups-edit-dialog',
  templateUrl: './business-groups-edit-dialog.component.html',
  styleUrls: ['./business-groups-edit-dialog.component.scss']
})
export class BusinessGroupsEditDialogComponent implements OnInit {
  editGroupForm: FormGroup;
  name = new FormControl('', Validators.required);
  code = new FormControl('');
  active = new FormControl('', Validators.required);
  useFincentricHost = new FormControl('', Validators.required);
  usePrivateKey = new FormControl('', Validators.required);
  questionnaireId = new FormControl('');
  cmEnabled = new FormControl('');
  rpmEnabled = new FormControl('');
  ftpUser = new FormControl('');
  ftpPass = new FormControl('');
  ftpProcessFlag = new FormControl('');
  ftpProcessType = new FormControl('', Validators.required);
  currency = new FormControl('')
  externalFtpHost = new FormControl('');
  externalFtpUser = new FormControl('');
  externalFtpPassword = new FormControl('');
  externalFtpPath = new FormControl('');
  type = new FormControl({value: '', disabled: true}, Validators.required);
  evalEnabled = new FormControl('');
  aggregator = new FormControl('');
  publishOnUpload = new FormControl('');
  process6373flag = new FormControl('');

  connectionType =  new FormControl('');

  currencyList = Object.values(GROUP_CURRENCY);
  filteredCurrency: string[] = [...this.currencyList];
  selectedType = '';
  usePrivateKeyChecked = false;

  constructor(public dialogRef: MatDialogRef<BusinessGroupsEditDialogComponent>,
              private formBuilder: FormBuilder,
              private store: Store,
              @Inject(MAT_DIALOG_DATA) public data: Group) {
    this.usePrivateKeyChecked = this.data.secretType === 'PRIVATE_KEY';
  }

  ngOnInit() {
    this.editGroupForm = this.formBuilder.group({
      name: this.name,
      code: this.code,
      questionnaireId: this.questionnaireId,
      active: this.active,
      useFincentricHost: this.useFincentricHost,
      cmEnabled: this.cmEnabled,
      rpmEnabled: this.rpmEnabled,
      ftpPass: this.ftpPass,
      ftpUser: this.ftpUser,
      ftpProcessFlag: this.ftpProcessFlag,
      ftpProcessType: this.ftpProcessType,
      currency: this.currency,
      externalFtpHost: this.externalFtpHost,
      externalFtpUser: this.externalFtpUser,
      externalFtpPassword: this.externalFtpPassword,
      externalFtpPath: this.externalFtpPath,
      type: this.type,
      evalEnabled: this.evalEnabled,
      aggregator: this.aggregator,
      publishOnUpload: this.publishOnUpload,
      process6373flag: this.process6373flag,
      connectionType: this.connectionType,
      usePrivateKey: this.usePrivateKey
    });
  }

  submit(): void {
    this.data.aggregator = this.data.orgAggregator;
    this.data.publishOnUpload = this.data.orgPublishOnUpload;
    this.data.secretType = this.usePrivateKeyChecked ? 'PRIVATE_KEY' : 'PASSWORD';
    this.store.dispatch(new groupsActions.UpdateGroup(this.data))
      .pipe(catchError(error => this.onServerErrorHandling(error, this.editGroupForm)))
      .subscribe((data) => {
        this.dialogRef.close(this.data);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSearchCurrency(event: string): string[] {
    const search = event && event.trim().toLowerCase();
    if (!search) {
      return this.filteredCurrency = [...this.currencyList];
    }
    this.filteredCurrency = this.currencyList.filter(option => option.toLowerCase().indexOf(search) === 0);
    return this.filteredCurrency;
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({backend: beError.defaultMessage});
      });
    }
    return NEVER;
  }

  onChangeFtpProcessType($event: string): void {
    if ($event !== 'EXTERNAL') {
      this.externalFtpHost.setValue('');
      this.externalFtpPath.setValue('');
      this.externalFtpUser.setValue('');
      this.externalFtpPassword.setValue('');
      this.connectionType.setValue(null);
    }
  }

  onChangeType(event: string): void {
    if (event) {
      this.selectedType = event;
      switch (event) {
        case 'CSA_BROKER':
          // CSA Broker
          this.editGroupForm.controls['rpmEnabled'].disable();
          this.editGroupForm.controls['evalEnabled'].disable();
          break;
        case 'ASSET_MANAGER':
          // Asset Manager
          this.editGroupForm.controls['rpmEnabled'].disable();
          this.editGroupForm.controls['evalEnabled'].enable();
          break;
        case 'SERVICE_PROVIDER':
          // Service Provider
          this.editGroupForm.controls['evalEnabled'].disable();
          this.editGroupForm.controls['rpmEnabled'].enable();
          break;
      }
    }
  }

  sppChanged({checked}): void {
    if(!checked){
      this.data.orgAggregator = false;
      this.data.orgPublishOnUpload = false;
    }
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BusinessTeamsPageComponent } from './components/business-teams-page/business-teams-page.component';


@NgModule({
  imports: [RouterModule.forChild([
    {
      path: ':businessUnitId/businessTeams',
      component: BusinessTeamsPageComponent,
    },
  ])],
  exports: [RouterModule],
  providers: []
})
export class BusinessTeamsRoutingModule {
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';
import { NgxsModule } from '@ngxs/store';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { ServicesRoutingModule } from './services-routing.module';

/* Services */
import { ServicesService } from './services/services.service';

/* States */
import { ServicesState } from './services.state';

/* Components */
import { ServicesPageComponent } from './components/services-page/services-page.component';
import { ServiceEditDialogComponent } from './components/service-edit-dialog/service-edit-dialog.component';


@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      ServicesState
    ]),
    SharedModule,
    ServicesRoutingModule
  ],
  declarations: [
    ServicesPageComponent,
    ServiceEditDialogComponent
  ],
  providers: [ServicesService],
  entryComponents: [ServiceEditDialogComponent],
  exports: []
})
export class ServicesModule {
}

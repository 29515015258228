import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { ProfileService } from '../../services/profile.service';

import { ApplicationInfo } from '../../../application-info';


@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {

  applicationInfo = {};
  model: any = {};
  loading = false;
  serverError = '';
  password = new FormControl('', Validators.required);
  email = new FormControl('', [Validators.required, Validators.email]);
  form: FormGroup = new FormGroup({
    email: this.email,
    password: this.password,
  });

  constructor(public router: Router,
              public authService: AuthService,
              public profileService: ProfileService) {
    this.applicationInfo = ApplicationInfo;

  }

  ngOnInit() {
  }

  login() {
    this.loading = true;
    this.authService.loginUser(this.model.email, this.model.password)
      .subscribe(
        _ => {
       //   const userHomePage = this.profileService.getDefaultHomePage();
        //  this.router.navigate([userHomePage]);
          this.router.navigate(['main', 'organizations']);
        },
        error => {
          this.serverError = error.error.message;
          const DISABLE_LOGIN_BTN_TIME = 3000;
          setTimeout(() => {
            this.loading = false;
          }, DISABLE_LOGIN_BTN_TIME);
        });
  }
}

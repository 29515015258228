import { Component } from '@angular/core';
import { MenuItem } from 'app/app-modules/core/components/brs-primary-menu/menu-item.model';

export const ORGANIZATIONS_ROUTE = <MenuItem[]>[
  {
    title: 'Organizations',
    alias: 'organizations',
    routerLink: '../organizations',
  }
];

@Component({
  selector: 'app-organizations-page',
  templateUrl: './organizations-page.component.html'
})
export class OrganizationsPageComponent {

  public menuItems: any[];

  constructor() {
    this.menuItems = ORGANIZATIONS_ROUTE;
  }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { OpenidSsoService } from '../../services/openid-sso.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  private url = 'sso-login';
  private subscription: Subscription;
  constructor(public authService: AuthService,
              public storageService: StorageService,
              public openIdSsoService: OpenidSsoService) {
}
  ngOnInit() {
    this.logout();
  }

  logIn() {
    this.authService.replaceUrl(`/${this.url}`);
  }

  private logout(): void {
    this.openIdSsoService.logOut();
    this.storageService.cleanStorage();
    this.subscription = this.authService.logout()
    .subscribe(
      () => {
      this.storageService.cleanStorage();
    },
    error => {
      console.log(error.error.message);
    });
  }

}

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ServiceUnavailableInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (ev: HttpEvent<any>) => this.onSuccess(ev),
        (err: any) => this.onError(err)
      )
    );
  }

  private onSuccess(ev: HttpEvent<any>) {
    if (ev instanceof HttpResponse) {
    }
  }

  private onError(err: any) {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 502:
        case 503:
        case 504:
           this.router.navigateByUrl(`page-unavailable`, {
            skipLocationChange: true
          });
           break;
        default:
          return;
      }
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { User } from '../../models/users.model';
import { NEVER, Observable } from 'rxjs';
import * as userActions from '../../users.actions';
import { catchError } from 'rxjs/operators';
import { ToastComponent } from 'app/shared/toast/toast.component';

@Component({
  selector: 'app-create-csa-user',
  templateUrl: './create-csa-user.component.html',
  styleUrls: ['./create-csa-user.component.scss']
})
export class CreateCsaUserComponent implements OnInit {
  addUserForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  firstName = new FormControl('', Validators.required);
  lastName = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<CreateCsaUserComponent>,
    private formBuilder: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: User,
    public toast: ToastComponent
  ) { }

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName
    });
  }

  addCsaUser(userForm: NgForm): void {
    this.store
      .dispatch(new userActions.AddCsaUser(this.addUserForm.value))
      .pipe(
        catchError((error) =>
          this.onServerErrorHandling(error, this.addUserForm)
        )
      )
      .subscribe((item) => {
        this.addUserForm.reset({ active: true });
        userForm.resetForm({ active: true });
        this.dialogRef.close(this.data);
        this.toast.setMessage('User added successfully.', 'success');
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private onServerErrorHandling(error, form: FormGroup): Observable<any> {
    let validationErrors = error.error.validationErrors;
    if (validationErrors) {
      validationErrors.forEach((beError) => {
        form.controls[beError.field].setErrors({
          backend: beError.defaultMessage
        });
      });
    }

    return NEVER;
  }
}

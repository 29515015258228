import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BusinessUnitsPageComponent } from './components/business-unit-page/business-units-page.component';
import { BusinessTeamsModule } from '../business-teams/business-teams.module';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: ':businessGroupId/businessUnit',
      component: BusinessUnitsPageComponent,
    },
    { path: ':businessGroupId/businessUnit', loadChildren: () => BusinessTeamsModule },
  ])],
  exports: [RouterModule],
  providers: []
})
export class BusinessUnitsRoutingModule {
}

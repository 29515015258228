import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OpenidSsoService } from '../../services/openid-sso.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../../services/storage.service';

@Component({
  templateUrl: './sso-login-page.component.html',
  styleUrls: ['./sso-login-page.component.scss']
})
export class SsoLoginPageComponent implements OnInit, OnDestroy {

  private ssoSubscription: Subscription;

  constructor (
    private route: ActivatedRoute,
    private openIdSsoService: OpenidSsoService,
    public storageService: StorageService
  ) {}

  ngOnInit() {
    // make sure to clean up old session data
    this.openIdSsoService.logOut();
    this.storageService.cleanStorage();

    this.ssoLogin();
  }

  ssoLogin() {
    const redirectUri = this.route.snapshot.queryParams['returnUrl'] || window.location.origin + '/authorize';
    this.openIdSsoService.configureSsoLogin(redirectUri);
    this.ssoSubscription = this.openIdSsoService.subject.subscribe(msg => {
      if (msg.type === 'error') {
        this.ssoErrorHandler(msg.event);
      } else {
        this.ssoSuccessHandler(msg.event);
      }
    });
    this.openIdSsoService.proceedSsoLogin();
  }

  ssoSuccessHandler(event) {
    console.warn(event);
  }

  ssoErrorHandler(event) {
    console.error(event);
  }

  ngOnDestroy() {
    this.ssoSubscription.unsubscribe();
  }
}

import { BusinessTeam } from './models/business-team.model';

export class AddBusinessTeam {
  static readonly type = '[BusinessTeam] Add';

  constructor(public payload: BusinessTeam) {
  }
}

export class GetBusinessTeamList {
  static readonly type = '[BusinessTeam] Business Unit Team List';

  constructor(public businessUnitId: string) {
  }
}

export class GetOrganizationBusinessTeamList {
  static readonly type = '[BusinessTeam] Get Organization Team List';
  constructor(public orgId: string) {
  }
}

export class UpdateBusinessTeam {
  static readonly type = '[BusinessTeam] Update';

  constructor(public payload: BusinessTeam) {
  }
}

export class DeleteBusinessTeam {
  static readonly type = '[BusinessTeam] Delete';

  constructor(public payload: BusinessTeam) {
  }
}

export class SetSelectedBusinessTeam {
  static readonly type = '[BusinessTeam] Set';

  constructor(public payload: BusinessTeam) {
  }
}

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxBreadcrumbsModule } from 'ng8-breadcrumbs';
import { NgxsModule } from '@ngxs/store';

/* Modules */
import { MaterialModule } from '../../material.module';
import { SharedModule } from '../../shared/shared.module';
import { BusinessUnitsRoutingModule } from './business-units-routing.module';
import { BusinessTeamsModule } from '../business-teams/business-teams.module';

/* Services */
import { BusinessUnitsService } from './services/business-units.service';

/* States */
import { BusinessUnitsState } from './business-units.state';

/* Components */
import { BusinessUnitsPageComponent } from './components/business-unit-page/business-units-page.component';
import { BusinessUnitEditDialogComponent } from './components/business-unit-edit-dialog/business-unit-edit-dialog.component';
import { BusinessUnitListViewComponent } from './components/business-unit-list-view/business-unit-list-view.component';


@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    MaterialModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    NgxBreadcrumbsModule,
    NgxsModule.forFeature([
      BusinessUnitsState
    ]),
    SharedModule,
    BusinessTeamsModule,
    BusinessUnitsRoutingModule
  ],
  declarations: [
    BusinessUnitsPageComponent,
    BusinessUnitListViewComponent,
    BusinessUnitEditDialogComponent,
  ],
  providers: [BusinessUnitsService],
  exports: [BusinessUnitListViewComponent]
})
export class BusinessUnitsModule {
}


import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtTokenService } from '../services/jwt-token.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenExpiredInterceptor implements HttpInterceptor {

  constructor(
    public authService: AuthService,
    public jwtService: JwtTokenService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = request.headers.get('brs_token');
    const isExpired = token && this.jwtService.isTokenExpired(token);

    return next.handle(request)
      .pipe(
        tap(_response => {
          if (isExpired && !this.isLoginOrLogoutApi(request)) {
            this.authService.replaceUrl('/logout');
          }
        })
      );
  }

  isLoginOrLogoutApi(req) {
    return req.url.includes('api/login') || req.url.includes('api/logout');
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NEVER, Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { catchError, takeUntil, distinctUntilChanged, filter  } from 'rxjs/operators';

import { Organization } from '../../models/organization.model';
import { OrganizationState } from '../../organizations.state';
import * as orgsActions from '../../organizations.actions';
import { ToastComponent } from '../../../../shared/toast/toast.component';
import { OrgEditDialogComponent } from '../org-edit-dialog/org-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-organizations-list',
  templateUrl: './organizations-list.component.html',
  styleUrls: ['./organizations-list.component.scss']
})
export class OrganizationsListComponent implements OnInit {
  @ViewChild('filter', {static: true}) filter: ElementRef;
  @ViewChild(DatatableComponent, {static: false}) table: DatatableComponent;

  @Select(OrganizationState.getOrganizationsList) orgList$: Observable<Organization[]>;

  isLoading = false;
  editing = {};
  rows = [];
  datasource = null;
  public ngUnsubscribe = new Subject();

  constructor(protected router: Router,
              public route: ActivatedRoute,
              private store: Store,
              public toast: ToastComponent,
              public dialog: MatDialog) {
    this.isLoading = true;
    this.store.dispatch(new orgsActions.GetOrganizations());
  }

  ngOnInit() {
    this.orgList$
      .pipe(
        filter(x => !!x),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((items) => {
        this.rows = [...items];
        this.datasource = [...this.rows];
        this.isLoading = false;
      });
  }


  updateValue(event, cell, rowIndex) {
    console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + '-' + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    this.datasource = [...this.rows];
    this.store.dispatch(new orgsActions.UpdateOrganization(this.rows[rowIndex]));
    console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  deleteOrg(org: Organization) {
    this.store.dispatch(new orgsActions.DeleteOrganization(org))
      .pipe(catchError(error => this.onServerErrorHandling(error)))
      .subscribe(() => {
        this.toast.setMessage('item deleted successfully.', 'success');
      });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.rows.filter((d) => {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.datasource = temp;
    this.table.offset = 0;
  }

  startEdit(row) {
    const dialogRef = this.dialog.open(OrgEditDialogComponent, {
      data: {...row}
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.toast.setMessage('item updated successfully.', 'success');
        }

      });
  }

  navigateToHierarhy(org: Organization) {
    this.store.dispatch(new orgsActions.SetSelectedOrganization(org));
    this.router.navigate(['../', `${org.id}`, 'hierarchy'], {relativeTo: this.route});
  }

  navigateToContact(org: Organization) {
    this.store.dispatch(new orgsActions.SetSelectedOrganization(org));
    this.router.navigate(['../', `${org.id}`, 'contact'], {relativeTo: this.route});
  }

  navigateToUsers(org: Organization) {
    this.store.dispatch(new orgsActions.SetSelectedOrganization(org));
    this.router.navigate(['../', `${org.id}`, 'users'], {relativeTo: this.route});
  }

  onServerErrorHandling(error) {
    this.toast.setMessage(error.error && error.error.message, 'danger', 5000);
    return NEVER;
  }

  getListOfEnabledProduct(row: Organization): string {
    let enabledText = `
    ${row.evalEnabled ? 'Eval;' : ''}
    ${row.cmEnabled ? 'CM;' : ''}
    ${ row.rmpEnabled ? 'SPP;' : ''}
    `;
    enabledText = !(row.evalEnabled || row.cmEnabled || row.rmpEnabled) ? 'No' : enabledText;
    return enabledText;
  }

  getRowHeight(row: Organization) {
    return 40;
  }

  getRowClass(row: Organization) {
    return {
      'text-transparent': !row.active
    };
  }
}
